import { SET_TIMER } from "../Redux/constants"
import store from "../Redux/store"

let interval = false

export function randomInteger(min, max) {
    let rand = min + Math.random() * (max + 1 - min)
    return Math.floor(rand)
}

export function setTitle(path, routeArray) {
    let pageTitle
    
	for (let i = 0; i < routeArray.length; i++) {
		if (routeArray[i].path === path) {
			pageTitle = routeArray[i].title
		}
    }
    
	document.title = (pageTitle) ? pageTitle : 'LiveFrame'
}

export function startTimer(time) {
	let currentTime = new Date().getTime()
	let timer = new Date(time).getTime() - currentTime

	store.dispatch({
		type: SET_TIMER,
		payload: {time: timer < 1000 ? 0 : timer, initialTime: time}
	})
			
	if(!!interval) {
		clearInterval(interval)
		interval = false
	}
	
	interval = setInterval(() => {
		if(timer < 1000) {
			clearInterval(interval)
			store.dispatch({
				type: SET_TIMER,
				payload: {time: 0, initialTime: time}
			})
		} else {
			timer-=1000
			store.dispatch({
				type: SET_TIMER,
				payload: {time: timer, initialTime: time}
			})
		}
	}, 1000)
}

export function capitalizeFirstLetter(string) {
	return string.charAt(0).toUpperCase() + string.slice(1)
}

export function getAvatarColor(input) {
	let AvatarColors = [
		'red.500',
		'green.500',
		'pink.500',
		'purple.500',
		'blue.500',
		'linkedin.500',
		'whatsapp.500',
		'teal.500',
		'cyan.500',
		'orange.500',
	]

	return AvatarColors[String(getHash(input)).slice(-1)]
}

function getHash(input){
	var hash = 0, len = input.length;
	for (var i = 0; i < len; i++) {
	  hash  = ((hash << 5) - hash) + input.charCodeAt(i);
	  hash |= 0; // to 32bit integer
	}
	return hash;
}