import { handleError, handleSuccess } from "../"
import CookieController from "../../Controllers/CookieController"

let apiUrl = `${process.env.REACT_APP_API_URL}/api/${process.env.REACT_APP_API_VERSION}/app/admin/stat/`

const apis = {
    getOnlineStat: function(params = {}) {
        params.projectId = process.env.REACT_APP_PROJECT_ID
        let searchParameters = new URLSearchParams()
            
        Object.keys(params).forEach(function(parameterName) {
            searchParameters.append(parameterName, params[parameterName])
        })

        return fetch(`${apiUrl}get-online?${searchParameters}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${CookieController.get('appToken')}`
            },
        })
        .then(handleSuccess)
        .catch(e => {
            console.error('admin stat api "getOnlineStat" error:', e)
            return handleError()
        })
    },
    getGeoStat: function(params = {}) {
        params.projectId = process.env.REACT_APP_PROJECT_ID
        let searchParameters = new URLSearchParams()
            
        Object.keys(params).forEach(function(parameterName) {
            searchParameters.append(parameterName, params[parameterName])
        })

        return fetch(`${apiUrl}get-geo?${searchParameters}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${CookieController.get('appToken')}`
            },
        })
        .then(handleSuccess)
        .catch(e => {
            console.error('admin stat api "getGeoStat" error:', e)
            return handleError()
        })
    },
    getCommonStat: function(params = {}) {
        params.projectId = process.env.REACT_APP_PROJECT_ID
        let searchParameters = new URLSearchParams()
            
        Object.keys(params).forEach(function(parameterName) {
            searchParameters.append(parameterName, params[parameterName])
        })

        return fetch(`${apiUrl}get-common?${searchParameters}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${CookieController.get('appToken')}`
            },
        })
        .then(handleSuccess)
        .catch(e => {
            console.error('admin stat api "getCommonStat" error:', e)
            return handleError()
        })
    },
}

export default apis
