import React from 'react'

class Iframe extends React.Component {
    state = {
        liveVideoHeight: 0
    }
    
    liveVideoResize() {
        let liveVideo = document.getElementById('live-video')
        if(!liveVideo)
            return

        this.setState({
            liveVideoHeight: liveVideo.offsetWidth / 1.7777
        })
    }

    componentDidMount() {
        this.liveVideoResize()
        
        window.addEventListener('resize', this.liveVideoResize.bind(this))
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.liveVideoResize.bind(this))
    }

    render() {
        return <iframe id="live-video" src={this.props.src} style={{...this.props, height: this.state.liveVideoHeight}} 
            width={this.props.width} 
            height={this.props.height}
            allow="autoplay; fullscreen; picture-in-picture"
        />
    }
}

export default Iframe
