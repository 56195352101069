import React from 'react'
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import * as userActions from "../../../Redux/actions/user"
import * as appActions from "../../../Redux/actions/app"
import * as adminActions from "../../../Redux/actions/admin"
import { bindActionCreators } from "redux"
import { withCookies } from "react-cookie"
import adminStatApi from '../../../apis/admin/stat'
import appApi from '../../../apis/app'

import OnlineTab from './Online'
import GeoTab from './Geo'
import StatTab from './Stat'

import { 
    ChakraProvider, 
    Box, 
    Button, 
    IconButton, 
    Text, Progress, 
    useToast, 
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Badge,
} from '@chakra-ui/react'
import { MinusIcon, CheckIcon, DeleteIcon, SettingsIcon } from '@chakra-ui/icons'

import { jsPDF } from "jspdf";

const labels = [
    {label: 'Online over Time', state: 'onlineDate'},
    {label: 'Users by Regions', state: 'geoDate'},
]

class App extends React.Component {
    state = {
        onlineDate: '',
        geoDate: '',
        stat: {
            totalUsers: 0,
            totalLogins: 0,
        },
    }
    
    downloadPdf() {
        let pdf = new jsPDF({
            orientation: 'landscape',
            unit: 'px',
        })

        pdf.text("Total users", 10, 25)
        pdf.text(this.state.stat.totalUsers+'', 10, 45)

        pdf.text("Total logins", 150, 25)
        pdf.text(this.state.stat.totalLogins+'', 150, 45)

        pdf.addPage()

        const canvas = document.querySelectorAll("canvas")
        
        let index = 0

        canvas.forEach((canva) => {
            let canvasWidth = canva.clientWidth/1.5
            let canvasHeight = canva.clientHeight/1.5
            let pageWidth = canvasWidth > 600 ? 600 : canvasWidth

            if(pageWidth === 600) {
                let diff = canvasWidth - 600
                let percent = diff/600*100
                canvasHeight = canvasHeight*(100-percent)/100
            }

            let pageHeight = canvasHeight
            
            pdf.text(labels[index].label, 10, 25)
            pdf.text(this.state[labels[index].state], 300, 25)
            pdf.addImage(canva, 'PNG', 10, 40, pageWidth, pageHeight)
            
            if (index+1 < canvas.length) {
                pdf.addPage()
            }

            index++
        })

        pdf.save('Statistics.pdf')
    }

    render() {
        return (<>
            <ChakraProvider resetCSS>
                <Box minHeight="calc(100vh - 302px)" position='relative'>
                    <Box display="flex" flexWrap="wrap" justifyContent="center">
                        <StatTab setStat={(stat) => this.setState({stat})} />
                        <OnlineTab setDate={(date) => this.setState({onlineDate: date})} />
                        <GeoTab setDate={(date) => this.setState({geoDate: date})} />
                    </Box>
                    <Box mt={15}>
                        <Button display="block" m='0 auto' onClick={() => this.downloadPdf()}>Download PDF</Button>
                    </Box>
                </Box>
            </ChakraProvider>
        </>)
  }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        app: state.app,
        admin: state.admin,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        userActions: bindActionCreators(userActions, dispatch),
        appActions: bindActionCreators(appActions, dispatch),
        adminActions: bindActionCreators(adminActions, dispatch),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withCookies(withRouter(App)))

