import React from 'react'
import { withRouter, Link as RouterLink } from "react-router-dom"
import { connect } from "react-redux"
import * as userActions from "../Redux/actions/user"
import * as appActions from "../Redux/actions/app"
import * as chatActions from "../Redux/actions/chat"
import * as pollActions from "../Redux/actions/poll"
import { bindActionCreators } from "redux"
import { randomInteger, setTitle } from "../Controllers/FunctionsController"
import { withCookies } from "react-cookie"
import appApi from '../apis/app'
import authApi from '../apis/auth'
import chatApi from '../apis/chat'
import pollApi from '../apis/poll'
import Iframe from "../CommonComponents/Iframe"
import Message from "../Components/Message"
import Poll from "../Components/Poll"
import PollAnswer from "../Components/PollAnswer"
import { ChakraProvider, Box, Text, Icon } from '@chakra-ui/react'
import { CheckCircleIcon } from '@chakra-ui/icons'

class App extends React.Component {
    state = {

    }

  select = () => {
	this.props.select(this.props.option.number)
}

    render() {
    return (
      <ChakraProvider resetCSS>
        <Box
          borderRadius="5px"
          mb="5px"
          p="15px"
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          boxShadow="0 2px 4px 0 rgb(0 0 0 / 4%)"
          backgroundColor="#ffffff"
          cursor="pointer"
          onClick={this.select}
        >
          <CheckCircleIcon
            color={
              this.props.activeNumber === this.props.option.number
                ? 'whatsapp.500'
                : '#000'
            }
            opacity={
              this.props.activeNumber === this.props.option.number ? '1' : '0.2'
            }
          />
          <Text ml="10px" color="gray.500">
            {this.props.option.answer}
          </Text>
        </Box>
      </ChakraProvider>
    )
  }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        app: state.app,
        chat: state.chat,
        poll: state.poll,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        userActions: bindActionCreators(userActions, dispatch),
        appActions: bindActionCreators(appActions, dispatch),
        chatActions: bindActionCreators(chatActions, dispatch),
        pollActions: bindActionCreators(pollActions, dispatch),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withCookies(withRouter(App)))
