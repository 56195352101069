
import React from 'react'
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import * as userActions from "../../../Redux/actions/user"
import * as appActions from "../../../Redux/actions/app"
import * as adminActions from "../../../Redux/actions/admin"
import { bindActionCreators } from "redux"
import { withCookies } from "react-cookie"
import adminPollApi from '../../../apis/admin/poll'
import appApi from '../../../apis/app'
import { 
    ChakraProvider, 
    Box, 
    Badge, 
    IconButton, 
    Text, Progress, 
    useToast, 
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuItemOption,
    MenuGroup,
    MenuOptionGroup,
    Avatar, 
} from '@chakra-ui/react'
import { EditIcon, CheckIcon, DeleteIcon, SettingsIcon } from '@chakra-ui/icons'

import { getAvatarColor } from '../../../Controllers/FunctionsController'


class App extends React.Component {
    getName() {
        if(this.props.message.user.role === 'admin')
            return `Admin ${this.props.user._id === this.props.message.user._id ? '(Me)' : `(${this.props.message.user.email || this.props.message.user.uId})`}`
        else
            return this.props.message.user.email || this.props.message.user.uId
    }

    render() {
        return (<>
            <ChakraProvider resetCSS>
                <Box display="flex" m="7px 15px" maxWidth="100%">
                    <Avatar backgroundColor={getAvatarColor(this.props.message.user.email || this.props.message.user.uId)} size="sm" />
                    <Box ml="10px" width="100%">
                        <Text color="red.500" fontWeight="bold" fontSize="sm">
                        {this.getName()}
                        </Text>
                        <Text color="gray.500" overflowWrap="wrap" maxWidth="100%">
                            {this.props.message.text}
                        </Text>
                    </Box>
                </Box>
            </ChakraProvider>
        </>)
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        app: state.app,
        admin: state.admin,
        chats: state.chats,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        userActions: bindActionCreators(userActions, dispatch),
        appActions: bindActionCreators(appActions, dispatch),
        adminActions: bindActionCreators(adminActions, dispatch),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withCookies(withRouter(App)))
        