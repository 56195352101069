import React from 'react'
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import * as userActions from "../../../../Redux/actions/user"
import * as appActions from "../../../../Redux/actions/app"
import * as adminActions from "../../../../Redux/actions/admin"
import { bindActionCreators } from "redux"
import { withCookies } from "react-cookie"
import adminPollApi from '../../../../apis/admin/poll'
import appApi from '../../../../apis/app'
import { 
    ChakraProvider, 
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Text,
    FormLabel,
    Input,
    Button,
    IconButton,
    Checkbox
} from '@chakra-ui/react'
import { MinusIcon, AddIcon } from '@chakra-ui/icons'

class App extends React.Component {
    state = {
        answers: [],
        isFetching: false
    }    

    componentDidMount = () => {
        adminPollApi.getAnswers({pollId: this.props.pollId}).then(response => {
            if(response.success) {
                this.setState({answers: response.answers})
            }

            this.setState({isFetching: false})
        })
    }

    render() {
        return (
            <ChakraProvider resetCSS>
                <Modal
                    isOpen={true}
                    onClose={this.props.onClose}
                >
                    <ModalOverlay />
                    <ModalContent minW='500px'>
                        <ModalHeader>Free answers for '{this.props.pollId}'</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody pb={6}>
                            {this.state.answers.map(answer => {
                                return <>
                                    <Text fontSize='sm' fontWeight='bold'>{answer.user.email || answer.user.uId}</Text>
                                    <Text>{answer.text}</Text>
                                    <hr/>
                                </>
                            })}
                            <Text></Text>
                        </ModalBody>
                        
                        <ModalFooter>
                            <Button onClick={this.props.onClose}>Close</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </ChakraProvider>
        )
  }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        app: state.app,
        admin: state.admin,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        userActions: bindActionCreators(userActions, dispatch),
        appActions: bindActionCreators(appActions, dispatch),
        adminActions: bindActionCreators(adminActions, dispatch),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withCookies(withRouter(App)))
