import React from 'react'
import './App.css'

// Router
import AppRouter from './Router'

import Header from './Components/Header'
import MobileHeader from './Components/MobileHeader'
import Footer from './Components/Footer'
import CookieConsent from './Components/CookieConsent'

import { connect } from "react-redux"
import { withCookies } from "react-cookie"

class App extends React.Component {
    state = {
        showCookieConsent: true
    }

    componentDidMount() {
        const { cookies } = this.props
        let isCookieAccepted = cookies.get(process.env.REACT_APP_PROJECT_ID + '.isCookieAccepted')

        if(isCookieAccepted)
            this.setState({
                showCookieConsent: false
            })
    }

    declineCookie = () => {
        this.setState({
            showCookieConsent: false
        })
    }

    acceptCookie = () => {
        const { cookies } = this.props
        cookies.set(process.env.REACT_APP_PROJECT_ID + '.isCookieAccepted', true, { path: '/' })
        this.setState({
            showCookieConsent: false
        })
    }

    render() {
        return (<>
            <div className='desktop-view'>
                <Header />
            </div>
            <div className='mobile-view'>
                <MobileHeader />
            </div>
            <AppRouter />
            <Footer />
            {this.state.showCookieConsent && <div className="cookie-consent"><CookieConsent declineCookie={this.declineCookie} acceptCookie={this.acceptCookie} /></div>}
        </>)
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
    }
}

export default connect(
    mapStateToProps,
)(withCookies(App))