import React from "react"

// Router
import { Switch, Route, Redirect, withRouter } from "react-router-dom"
import routes from './config'

// Redux
import { connect } from "react-redux"
import * as userActions from "../Redux/actions/user"
import * as appActions from "../Redux/actions/app"
import { bindActionCreators } from "redux"

// Other functions
import { randomInteger, setTitle } from "../Controllers/FunctionsController"
// import SocketController from '../Controllers/SocketController'
import { withCookies } from "react-cookie"
// import { urlApi } from "../config"
// import CookieAccept from "../Partials/CookieAccept"
import appApi from '../apis/app'
import userApi from '../apis/user'

class AppRouter extends React.Component {
    state = {
        isRender: false
    }

    componentDidMount() {
        this.props.history.listen(() => {
            setTitle(this.props.history.location.pathname, routes)
        })
        
        setTitle(this.props.history.location.pathname, routes)

        appApi.getDataApp().then(response => {
            if(response.success) {
                this.props.appActions.getDataApp(response.data)
                this.setUser(response.data)
            }
        })
        .catch(() => {
            this.setState({isRender: true})
        })
    }

    setUser(app) {
        const { cookies } = this.props
        let apiToken = cookies.get(process.env.REACT_APP_PROJECT_ID + '.appToken')
        
        let uId = cookies.get(process.env.REACT_APP_PROJECT_ID + '.uId')
        
        if(!uId) {
            cookies.set(process.env.REACT_APP_PROJECT_ID + '.uId', randomInteger(1000000000, 9999999999), { path: '/' })
        }
    
        if(apiToken) {            
            userApi.getUser().then(response => {
                if(response.success) {
                    this.props.userActions.loginUser(response.user, apiToken)
                }
    
                this.setState({isRender: true})
            })
            .catch(() => {
                this.setState({isRender: true})
            })
        } else {
            this.setState({isRender: true})
        }
    }

    render() {
        return this.state.isRender && this.props.app.isGetted && (<>
        <Switch>
            {routes.map((route, index) => {
                switch (route.type) {
                    case 'all':
                        return <this.AllRoute
                            key={index}
                            path={route.path}
                            exact={route.exact}
                        >
                            <route.component />
                        </this.AllRoute>
                    case 'auth':
                        return <this.AuthRoute
                            key={index}
                            path={route.path}
                            exact={route.exact}
                        >
                            <route.component />
                        </this.AuthRoute>

                    case 'user':
                        return <this.PrivateRoute
                            key={index}
                            path={route.path}
                            exact={route.exact}
                        >
                            <route.component />
                        </this.PrivateRoute>
                    case 'admin':
                        return <this.AdminRoute
                            key={index}
                            path={route.path}
                            exact={route.exact}
                        >
                            <route.component />
                        </this.AdminRoute>
                    default:
                        return false
                }            
            })}

            <Redirect
                to={{
                    pathname: "/",
                }}
            />
        </Switch>
        </>
        )
    }

    AllRoute = ({ children, ...rest }) => {
        return (
            <Route
                {...rest}
                render={() => children}
            />
        )
    }

    PrivateRoute = ({ children, ...rest }) => {
        return (
            <Route
                {...rest}
                render={() =>
                    this.props.user.isAuth ? (
                        <>
                            {children}
                        </>
                    ) : (
                        <Redirect
                            to={{
                                pathname: "/sign-in",
                            }}
                        />
                    )
                }
            />
        )
    }

    AdminRoute = ({ children, ...rest }) => {
        return (
            <Route
                {...rest}
                render={() =>
                    this.props.user.role === 'admin' ? (
                        <>
                            {children}
                        </>
                    ) : (
                        <Redirect
                            to={{
                                pathname: "/",
                            }}
                        />
                    )
                }
            />
        )
    }

    AuthRoute = ({ children, ...rest }) => {
        return (
            <Route
                {...rest}
                render={() =>
                    !this.props.user.isAuth ? (
                        <>
                            {children}
                        </>
                    ) : (
                        <Redirect
                            to={{
                                pathname: "/",
                            }}
                        />
                    )
                }
            />
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        app: state.app
    }
}

function mapDispatchToProps(dispatch) {
    return {
        userActions: bindActionCreators(userActions, dispatch),
        appActions: bindActionCreators(appActions, dispatch),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withCookies(withRouter(AppRouter)))
