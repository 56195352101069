import { 
    GET_POLLS,
    REMOVE_POLL
} from '../constants'
// import { urlApi } from '../../config';

export const getPolls = (polls) => (dispatch) => {
    // fetch(`${urlApi}/api/poll/get-for-user`, {
    //     method: "post",
    //     headers: {
    //         'Accept': 'application/json',
    //         'Content-Type': 'application/json',
    //         Authorization: `Bearer ${apiToken}`,
    //     },
    // })
    // .then(response => response.json())
    // .then(({polls, error}) => {
    //     if(!error)
        dispatch({
            type: GET_POLLS,
            payload: polls
        })
    // })
}

export const vote = (pollId) => (dispatch) => {
    dispatch({
        type: REMOVE_POLL,
        payload: pollId
    })

    // fetch(`${urlApi}/api/poll/answer`, {
    //     method: "post",
    //     headers: {
    //         'Accept': 'application/json',
    //         'Content-Type': 'application/json',
    //         Authorization: `Bearer ${apiToken}`,
    //     },
    //     body: JSON.stringify({
    //         pollId,
    //         answerNumber,
    //         text
    //     })
    // })
}