import { 
    ADMIN_ADD_MESSAGE,
    ADMIN_GET_CHATS,
    ADMIN_READ,
    ADMIN_GET_CHAT,
    GET_USERS,
    ADD_USER,
    REMOVE_USER,
    ADMIN_REMOVE_POLL,
    ADMIN_ADD_POLL,
    ADMIN_GET_POLLS,
    SET_ACCESS,
    CHANGE_POLL_STATUS
} from '../constants'
import store from '../store';
// import { urlApi } from '../../config';
import SocketController from '../../Controllers/SocketController';

export const getChats = (chats) => (dispatch) => {
    chats = chats.map(chat => ({...chat, messages: [], isGetted: false}))

    dispatch({
        type: ADMIN_GET_CHATS,
        payload: chats
    })
}

export const getChat = (chatId, messages) => (dispatch) => {
    dispatch({
        type: ADMIN_GET_CHAT,
        payload: {chatId, messages}
    })
}

export const sendMessage = (chatId, text) => (dispatch) => {
    let message = {
        text,
        user: store.getState().user,
        createdAt: Date.now()
    }
    
    dispatch({
        type: ADMIN_ADD_MESSAGE,
        payload: {chatId, message}
    })
}

export const readMessages = (chatId) => (dispatch) => {
    dispatch({
        type: ADMIN_READ,
        payload: chatId
    })
}

export const getUsers = (users) => (dispatch) => {
    // fetch(`${urlApi}/api/admin/get`, {
    //     method: "post",
    //     headers: {
    //         'Accept': 'application/json',
    //         'Content-Type': 'application/json',
    //         Authorization: `Bearer ${apiToken}`,
    //     }
    // })
    // .then(response => response.json())
    // .then(({users}) => {
        dispatch({
            type: GET_USERS,
            payload: users
        })
    // })
}

export const addUser = (user) => (dispatch) => {
    dispatch({
        type: ADD_USER,
        payload: user
    })

    // fetch(`${urlApi}/api/admin/create`, {
    //     method: "post",
    //     headers: {
    //         'Accept': 'application/json',
    //         'Content-Type': 'application/json',
    //         Authorization: `Bearer ${apiToken}`,
    //     },
    //     body: JSON.stringify(user)
    // })
    // .then(response => response.json())
    // .then(({user}) => {
        
    // })
}

export const removeUser = (email) => (dispatch) => {
    dispatch({
        type: REMOVE_USER,
        payload: email
    })

    // fetch(`${urlApi}/api/admin/remove`, {
    //     method: "post",
    //     headers: {
    //         'Accept': 'application/json',
    //         'Content-Type': 'application/json',
    //         Authorization: `Bearer ${apiToken}`,
    //     },
    //     body: JSON.stringify({
    //         email
    //     })
    // })
}

export const getPolls = (polls) => (dispatch) => {
    dispatch({
        type: ADMIN_GET_POLLS,
        payload: polls
    })
}

export const addPoll = (poll) => (dispatch) => {
    poll = {...poll, count: 0, isEnabled: true, options: poll.options.map((option, index) => {
        return {...option, count: 0}
    })}

    dispatch({
        type: ADMIN_ADD_POLL,
        payload: poll
    })
}

export const removePoll = (question) => (dispatch) => {
    dispatch({
        type: ADMIN_REMOVE_POLL,
        payload: question
    })
}

export const togglePoll = (question, isEnabled) => (dispatch) => {
    dispatch({
        type: CHANGE_POLL_STATUS,
        payload: {question, isEnabled}
    })
}