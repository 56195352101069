import React from 'react'

// Pages
import Login from '../Components/Login'
import Logout from '../Components/Logout'
import Home from '../Components/Home'
import Event from '../Components/Event'
    import Dashboard from '../Pages/Dashboard'

const routes = [
    {
        path: '/dashboard',
        exact: false,
        type: 'admin',
        title: 'Dashboard',
        component: () => <Dashboard />
    },
    {
        path: '/login',
        exact: false,
        type: 'auth',
        title: 'Telekom Mobile Internal Meeting',
        component: () => <Login />
    },
    {
        path: '/logout',
        exact: false,
        type: 'all',
        title: 'Telekom Mobile Internal Meeting',
        component: () => <Logout />
    },
    {
        path: '/',
        exact: true,
        type: 'all',
        title: 'Telekom Mobile Internal Meeting',
        component: () => <Home />
    },
    {
        path: '/event',
        exact: false,
        type: 'user',
        title: 'Telekom Mobile Internal Meeting',
        component: () => <Event />
    },
]

export default routes