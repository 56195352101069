import { createStandaloneToast } from '@chakra-ui/react'

const toast = createStandaloneToast()

export function handleError(errors = []) {
    errors = errors.length !== 0 ? errors : [{param: 'all', msg: 'Something goes wrong...'}]

    // errors.map(error => {
    //     toast({
    //         title: 'API Error:',
    //         description: `${error.param} - ${error.msg}`,
    //         status: 'error',
    //         duration: 5000,
    //         isClosable: true,
    //     })
    // })
    
    return {success: false, errors }
}

export const handleSuccess = async response => {
    let data = await response.json()
    if(response.ok) 
        return data

    return handleError(data.errors)
}