import React from 'react'
import { withRouter, Link as RouterLink } from "react-router-dom"
import { connect } from "react-redux"
import * as userActions from "../Redux/actions/user"
import * as appActions from "../Redux/actions/app"
import * as chatActions from "../Redux/actions/chat"
import * as pollActions from "../Redux/actions/poll"
import { bindActionCreators } from "redux"
import { randomInteger, setTitle } from "../Controllers/FunctionsController"
import { withCookies } from "react-cookie"
import appApi from '../apis/app'
import authApi from '../apis/auth'
import chatApi from '../apis/chat'
import pollApi from '../apis/poll'
import Iframe from "../CommonComponents/Iframe"
import Message from "../Components/Message"
import Poll from "../Components/Poll"
import PollAnswer from "../Components/PollAnswer"
import { ChakraProvider, Box, Image, Link } from '@chakra-ui/react'

class App extends React.Component {
    state = {

    }

  

    render() {
    return (
      <ChakraProvider resetCSS>
        <Box
          display="flex"
          pl="50px"
          pr="50px"
          pt="15px"
          pb="15px"
          backgroundColor="#ffffff"
          boxShadow="0 2px 4px 0 rgb(0 0 0 / 4%)"
        >
          <Image
            src="https://api.liveframe.eu/media/1I6FbdJZ1WS5NnXTuNvQhPAO.jpg"
            minHeight="60px"
            maxHeight="60px"
            mr="30px"
          />
          <Box display="flex" width="100%">
            <Link
as={RouterLink}
              display="flex"
              justifyContent="center"
              alignItems="center"
              to="/"
              fontSize="xl"
              color="gray.500"
              pl="30px"
              pr="30px"
            >
              Home
            </Link>
            <Link
as={RouterLink}
              display={
                !!(this.props.user.isAuth && this.props.user.role === 'admin')
                  ? 'flex'
                  : 'none'
              }
              justifyContent="center"
              alignItems="center"
              to="/dashboard"
              fontSize="xl"
              color="gray.500"
              pl="30px"
              pr="30px"
            >
              Dashboard
            </Link>
          </Box>
          <Link
as={RouterLink}
            justifyContent="center"
            alignItems="center"
            m="auto"
            height="60px"
            width="300px"
            fontWeight="bold"
            textAlign="center"
            color="#fff"
            backgroundColor="#e30073"
            to="/login"
            borderRadius="5px"
            p="17px"
            display={!this.props.user.isAuth ? 'flex' : 'none'}
          >
            Sign in
          </Link>
          <Link
as={RouterLink}
            justifyContent="center"
            alignItems="center"
            m="auto"
            height="60px"
            width="300px"
            fontWeight="bold"
            textAlign="center"
            color="#fff"
            backgroundColor="#e30073"
            to="/register"
            borderRadius="5px"
            p="17px"
            display={
              this.props.app.isEnabledRegistration && !this.props.user.isAuth
                ? 'flex'
                : 'none'
            }
            ml="15px"
          >
            Sign up
          </Link>
          <Link
as={RouterLink}
            justifyContent="center"
            alignItems="center"
            m="auto"
            height="60px"
            width="300px"
            fontWeight="bold"
            textAlign="center"
            color="#fff"
            backgroundColor="gray.500"
            to="/logout"
            borderRadius="5px"
            p="17px"
            display={this.props.user.isAuth ? 'flex' : 'none'}
            ml="15px"
          >
            Log out
          </Link>
        </Box>
      </ChakraProvider>
    )
  }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        app: state.app,
        chat: state.chat,
        poll: state.poll,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        userActions: bindActionCreators(userActions, dispatch),
        appActions: bindActionCreators(appActions, dispatch),
        chatActions: bindActionCreators(chatActions, dispatch),
        pollActions: bindActionCreators(pollActions, dispatch),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withCookies(withRouter(App)))
