import React from 'react'
import { withRouter, Link as RouterLink } from "react-router-dom"
import { connect } from "react-redux"
import * as userActions from "../Redux/actions/user"
import * as appActions from "../Redux/actions/app"
import * as chatActions from "../Redux/actions/chat"
import * as pollActions from "../Redux/actions/poll"
import { bindActionCreators } from "redux"
import { randomInteger, setTitle } from "../Controllers/FunctionsController"
import { withCookies } from "react-cookie"
import appApi from '../apis/app'
import authApi from '../apis/auth'
import chatApi from '../apis/chat'
import pollApi from '../apis/poll'
import Iframe from "../CommonComponents/Iframe"
import Message from "../Components/Message"
import Poll from "../Components/Poll"
import PollAnswer from "../Components/PollAnswer"
import {
  ChakraProvider,
  Box,
  Text,
  Button,
  Icon,
  Textarea
} from '@chakra-ui/react'
import { CheckCircleIcon } from '@chakra-ui/icons'

class App extends React.Component {
    state = {
        activeNumber: false,
        currentPollId: "",
        text: "",
    }

  componentDidMount() {
	if(!this.props.poll.isGetted)
        pollApi.getPolls().then(response => {
          	if(response.success) {
                this.props.pollActions.getPolls(response.polls)

              	if(!!response.polls.length)
                    this.setState({currentPollId: response.polls[0]._id})
            }
        })
}

componentDidUpdate(prevProps, prevState) {
	if(
      (!!this.props.poll.polls.length && 
      this.props.poll.polls[0]._id !== prevState.currentPollId) || 
      (!this.props.poll.polls.length && !!prevProps.poll.polls.length)
    )
      	this.setState({
          activeNumber: false,
          text: '',
          currentPollId: !!this.props.poll.polls.length ? 
          	this.props.poll.polls[0]._id :
          	''
        })
}

getAnswers() {
	return !!this.props.poll.polls.length && this.props.poll.polls[0].options
      .map(option => <PollAnswer 
           activeNumber={this.state.activeNumber} 
		   select={(activeNumber) => {
             	this.setState({
                  activeNumber, 
                  currentPollId: this.props.poll.polls[0]._id
                })
           }} 
           option={option} 
	   />)
}

vote = () => {
	pollApi.vote({
      number: this.state.activeNumber, 
      pollId: this.props.poll.polls[0]._id,
      text: this.state.text,
    })
  
  	this.setState({activeNumber: false, text: ''})
    
    this.props.pollActions.vote(this.props.poll.polls[0]._id)
}

    render() {
    return (
      <ChakraProvider resetCSS>
        <Box
          backgroundColor="red.500"
          borderRadius="5px"
          p="15px"
          boxShadow="0 2px 4px 0 rgb(0 0 0 / 4%)"
        >
          <Text color="#ffffff" fontWeight="bold" fontSize="xl" p={0} m={0}>
            {!!this.props.poll.polls.length
              ? this.props.poll.polls[0].question
              : 'Nu există sondaje încă'}
          </Text>
        </Box>
        <Box mt="10px">{this.getAnswers()}</Box>
        <Box
          borderRadius="5px"
          backgroundColor="#ffffff"
          p="15px"
          display={
            !!this.props.poll.polls.length &&
            this.props.poll.polls[0].isFreeAnswerAvailable
              ? 'flex'
              : 'none'
          }
          alignItems="center"
          cursor="pointer"
          onClick={() => this.setState({ activeNumber: 0 })}
          boxShadow="0 2px 4px 0 rgb(0 0 0 / 4%)"
        >
          <CheckCircleIcon
            color={this.state.activeNumber === 0 ? 'whatsapp.500' : '#000'}
            opacity={this.state.activeNumber === 0 ? '1' : '0.2'}
          />
          <Textarea
            placeholder="Răspunsul dvs"
            ml="10px"
            onChange={e => this.setState({ text: e.target.value })}
            value={this.state.text}
          />
        </Box>
        <Button
          variant="solid"
          size="md"
          display={this.state.activeNumber !== false ? 'flex' : 'none'}
          colorScheme="whatsapp"
          width="100%"
          mt="10px"
          onClick={this.vote}
        >
          Vot
        </Button>
      </ChakraProvider>
    )
  }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        app: state.app,
        chat: state.chat,
        poll: state.poll,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        userActions: bindActionCreators(userActions, dispatch),
        appActions: bindActionCreators(appActions, dispatch),
        chatActions: bindActionCreators(chatActions, dispatch),
        pollActions: bindActionCreators(pollActions, dispatch),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withCookies(withRouter(App)))
