import React from 'react'
import { withRouter, Link as RouterLink } from "react-router-dom"
import { connect } from "react-redux"
import * as userActions from "../Redux/actions/user"
import * as appActions from "../Redux/actions/app"
import * as chatActions from "../Redux/actions/chat"
import * as pollActions from "../Redux/actions/poll"
import { bindActionCreators } from "redux"
import { randomInteger, setTitle } from "../Controllers/FunctionsController"
import { withCookies } from "react-cookie"
import appApi from '../apis/app'
import authApi from '../apis/auth'
import chatApi from '../apis/chat'
import pollApi from '../apis/poll'
import Iframe from "../CommonComponents/Iframe"
import Message from "../Components/Message"
import Poll from "../Components/Poll"
import PollAnswer from "../Components/PollAnswer"
import {
  ChakraProvider,
  Container,
  Box,
  Text,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Button
} from '@chakra-ui/react'

class App extends React.Component {
    state = {
        email: "",
        token: "",
        errors: [],
        isFetching: false,
    }

  login = (e) => {
		e.preventDefault()
  
        if(this.state.isFetching)
            return
  
  		if(this.state.email !== 'telekommobile')
          return this.setState({errors: [{param: 'email', msg: 'Incorrect user'}]})
  
  		const { cookies } = this.props
        
        this.setState({isFetching: true, errors: []})

        authApi.login({
            email: this.state.email.toLowerCase().replace(/s+/g, ''),
            token: this.state.token
        }).then(response => {
            if(response.success) {
                this.props.userActions.loginUser(response.user, response.token)
              let expires = new Date()
            expires.setFullYear(expires.getFullYear()+1)
              cookies.set(process.env.REACT_APP_PROJECT_ID + '.appToken', response.token, { path: '/', expires })
                this.props.history.push('/')
            } else {
                this.setState({errors: response.errors})
            }

            this.setState({isFetching: false})
        })
    }

    render() {
    return (
      <ChakraProvider resetCSS>
        <Container display="flex" height="auto" flexDirection="column">
          <Box
            backgroundColor="#ffffff"
            p="30px 50px"
            boxShadow="0 7px 14px 0 rgb(0 0 0 / 7%)"
            borderRadius="10px"
          >
            <Text fontSize="4xl" fontWeight="bold" textAlign="center">
              Sign in
            </Text>
            <FormControl
              isInvalid={
                !!this.state.errors.filter(error => error.param === 'email')
                  .length
              }
              mb="20px"
            >
              <FormLabel mb="20px">User</FormLabel>
              <Input
                onChange={e => {
                  this.setState({ email: e.target.value })
                }}
              />
              <FormErrorMessage>
                {this.state.errors
                  .filter(error => error.param === 'email')
                  .map((error, index) => (
                    <span key={index} className="error-msg">
                      {error.msg}
                    </span>
                  ))}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={
                !!this.state.errors.filter(error => error.param === 'token')
                  .length
              }
              mb="20px"
            >
              <FormLabel mb="20px">Token</FormLabel>
              <Input
                type="password"
                onChange={e => {
                  this.setState({ token: e.target.value })
                }}
              />
              <FormErrorMessage>
                {this.state.errors
                  .filter(error => error.param === 'token')
                  .map((error, index) => (
                    <span key={index} className="error-msg">
                      {error.msg}
                    </span>
                  ))}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={
                !!this.state.errors.filter(error => error.param === 'all')
                  .length
              }
              mb="20px"
            >
              <FormErrorMessage mt={0}>
                {this.state.errors
                  .filter(error => error.param === 'all')
                  .map((error, index) => (
                    <span key={index} className="error-msg">
                      {error.msg}
                    </span>
                  ))}
              </FormErrorMessage>
            </FormControl>
            <Button
              variant="solid"
              size="md"
              display="flex"
              width="100%"
              onClick={this.login}
              backgroundColor="#e30073"
              color="#ffffff"
            >
              Sign in
            </Button>
          </Box>
        </Container>
      </ChakraProvider>
    )
  }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        app: state.app,
        chat: state.chat,
        poll: state.poll,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        userActions: bindActionCreators(userActions, dispatch),
        appActions: bindActionCreators(appActions, dispatch),
        chatActions: bindActionCreators(chatActions, dispatch),
        pollActions: bindActionCreators(pollActions, dispatch),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withCookies(withRouter(App)))
