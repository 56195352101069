const cookies = {
    set: (name,value,days) => {
        let expires = ""

        if (days) {
            let date = new Date()
            date.setTime(date.getTime() + (days*24*60*60*1000))
            expires = "; expires=" + date.toUTCString()
        }

        document.cookie = process.env.REACT_APP_PROJECT_ID + '.' + name + "=" + (value || "")  + expires + "; path=/"
    },
    get: (name) => {
        let nameEQ = process.env.REACT_APP_PROJECT_ID + '.' + name + "="
        let ca = document.cookie.split(';')

        for(let i=0;i < ca.length;i++) {
            let c = ca[i]

            while (c.charAt(0) === ' ') 
                c = c.substring(1, c.length)

            if (c.indexOf(nameEQ) === 0) 
                return c.substring(nameEQ.length, c.length)
        }

        return null
    },
    remove: (name) => {   
        document.cookie = process.env.REACT_APP_PROJECT_ID + '.' + name+'=; Max-Age=-99999999; path=/'
    }
}

export default cookies