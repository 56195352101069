
import React from 'react'
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import * as userActions from "../../../Redux/actions/user"
import * as appActions from "../../../Redux/actions/app"
import * as adminActions from "../../../Redux/actions/admin"
import { bindActionCreators } from "redux"
import { withCookies } from "react-cookie"
import adminStatApi from '../../../apis/admin/stat'
import appApi from '../../../apis/app'
import { 
    ChakraProvider, 
    Box, 
    Button, 
    IconButton, 
    Text, Progress, 
    useToast, 
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Badge,
    Input,
} from '@chakra-ui/react'
import { MinusIcon, CheckIcon, DeleteIcon, SettingsIcon } from '@chakra-ui/icons'

import Chart from 'chart.js/auto';

Date.prototype.toDateInputValue = (function() {
    let local = new Date(this);
    local.setMinutes(this.getMinutes() - this.getTimezoneOffset());
    return local.toJSON().slice(0,10);
});

let myChart = false

class App extends React.Component {
    state = {
        isFetching: true,
        date: new Date().toDateInputValue()
    }

    getStat() {
        this.setState({isFetching: true})

        adminStatApi.getOnlineStat({
            date: this.state.date
        }).then(response => {
            if(response.success) {
                this.renderChart(response.data)
            }

            this.setState({isFetching: false})
        })
    }

    componentDidMount() {
        if(!!myChart) {
            myChart.destroy()
            myChart = false
        }
        this.getStat()
        this.props.setDate(this.state.date)
    }

    renderChart(datapoints) {
        if(!!myChart) {
            myChart.data.datasets[0].data = datapoints
            myChart.update()
        } else {
            const ctx = document.getElementById(`onlineChart`)
            const DATA_COUNT = 24
            const labels = []

            for (let i = 0; i < DATA_COUNT; ++i) {
                labels.push(i.toString()+(i< 12 ? 'am' : 'pm'))
            }

            const data = {
                labels: labels,
                datasets: [
                    {
                        label: 'Number of online users',
                        data: datapoints,
                        borderColor: '#E53E3E',
                        backgroundColor: '#E53E3E',
                        fill: false,
                        cubicInterpolationMode: 'monotone',
                        tension: 0.4
                    },
                ]
            }

            myChart = new Chart(ctx, {
                type: 'line',
                data: data,
                options: {
                    elements: {
                        point:{
                            radius: 0
                        }
                    },
                    responsive: true,
                    plugins: {
                        title: {
                            display: false,
                        },
                        legend: {
                            display: false
                        }
                    },
                    interaction: {
                        intersect: false,
                    },
                    scales: {
                        x: {
                            display: true,
                            title: {
                                display: false,
                            },
                            grid: {
                                display: false
                            }
                        },
                        y: {
                            display: true,
                            title: {
                                display: false,
                            },
                            suggestedMin: 0,
                            suggestedMax: 100,
                            grid: {
                                display: false
                            }
                        }
                    }
                },
            })
        }
    }
    render() {
        return (<>
            <ChakraProvider resetCSS>
                <Box
                    maxWidth="100%"
                    width='container.lg'
                    minWidth="container.lg"
                    boxShadow="0 7px 14px 0 rgb(0 0 0 / 7%)"
                    backgroundColor="#ffffff"
                    borderRadius="5px"
                    p="15px"
                    mr="8px"
                    ml="8px"
                    mt="16px"
                    height='max-content'
                >
                    <Text fontWeight='bold' mb={'15px'} fontSize='xl' color='#718096'>Online over Time</Text>

                    <Input value={this.state.date} onChange={e => {
                        this.setState({date: e.target.value}, () => {
                            this.props.setDate(e.target.value)
                            this.getStat()
                        })
                    }} width='300px' mb={'15px'} placeholder='Date' type='date' />

                    <canvas id={`onlineChart`} width="100%" className="chart-online"></canvas>
                </Box>
            </ChakraProvider>
        </>)
  }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        app: state.app,
        admin: state.admin,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        userActions: bindActionCreators(userActions, dispatch),
        appActions: bindActionCreators(appActions, dispatch),
        adminActions: bindActionCreators(adminActions, dispatch),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withCookies(withRouter(App)))

