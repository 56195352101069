import React from 'react'
import { withRouter, Link as RouterLink } from "react-router-dom"
import { connect } from "react-redux"
import * as userActions from "../Redux/actions/user"
import * as appActions from "../Redux/actions/app"
import * as chatActions from "../Redux/actions/chat"
import * as pollActions from "../Redux/actions/poll"
import { bindActionCreators } from "redux"
import { randomInteger, setTitle } from "../Controllers/FunctionsController"
import { withCookies } from "react-cookie"
import appApi from '../apis/app'
import authApi from '../apis/auth'
import chatApi from '../apis/chat'
import pollApi from '../apis/poll'
import Iframe from "../CommonComponents/Iframe"
import Message from "../Components/Message"
import Poll from "../Components/Poll"
import PollAnswer from "../Components/PollAnswer"
import { ChakraProvider, Box, Image, IconButton, Link } from '@chakra-ui/react'
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons'

class App extends React.Component {
    state = {
        isOpened: false,
    }

  

    render() {
    return (
      <ChakraProvider resetCSS>
        <Box
          backgroundColor="#ffffff"
          boxShadow="0 2px 4px 0 rgb(0 0 0 / 4%)"
          display="flex"
          alignItems="center"
          p="15px 20px"
        >
          <Image
            src="https://api.liveframe.eu/media/1I6FbdJZ1WS5NnXTuNvQhPAO.jpg"
            minHeight="60px"
            maxHeight="60px"
          />
          <Box width="100%" />
          <IconButton
            aria-label="icon"
            icon={<HamburgerIcon />}
            size="md"
            colorScheme="gray"
            onClick={() => this.setState({ isOpened: true })}
          />
        </Box>
        <Box
          backgroundColor="#ffffff"
          boxShadow="0 2px 4px 0 rgb(0 0 0 / 4%)"
          display={this.state.isOpened ? 'flex' : 'none'}
          alignItems="flex-start"
          p="15px 20px"
          position="fixed"
          zIndex={1000}
          top={0}
          height="100vh"
          width="100%"
          flexDirection="column"
        >
          <Box display="flex" alignItems="center" width="100%">
            <Image
              src="https://api.liveframe.eu/media/1I6FbdJZ1WS5NnXTuNvQhPAO.jpg"
              minHeight="60px"
              maxHeight="60px"
            />
            <Box width="100%" />
            <IconButton
              aria-label="icon"
              icon={<CloseIcon />}
              size="md"
              colorScheme="gray"
              onClick={() => this.setState({ isOpened: false })}
            />
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            width="100%"
            alignItems="center"
            mt="30px"
          >
            <Link
as={RouterLink}
              to="/"
              color="gray.500"
              fontSize="xl"
              display="block"
              width="100%"
              textAlign="center"
              p="20px"
              onClick={() => this.setState({ isOpened: false })}
            >
              Home
            </Link>
            <Link
as={RouterLink}
              to="/dashboard"
              color="gray.500"
              fontSize="xl"
              display={
                !!(this.props.user.isAuth && this.props.user.role === 'admin')
                  ? 'flex'
                  : 'none'
              }
              width="100%"
              textAlign="center"
              p="20px"
              justifyContent="center"
              onClick={() => this.setState({ isOpened: false })}
            >
              Dashboard
            </Link>
            <Link
as={RouterLink}
              to="/login"
              color="gray.500"
              fontSize="xl"
              display={!this.props.user.isAuth ? 'flex' : 'none'}
              width="100%"
              textAlign="center"
              p="20px"
              justifyContent="center"
              onClick={() => this.setState({ isOpened: false })}
            >
              Sign in
            </Link>
            <Link
as={RouterLink}
              to="/register"
              color="gray.500"
              fontSize="xl"
              display={
                this.props.app.isEnabledRegistration && !this.props.user.isAuth
                  ? 'flex'
                  : 'none'
              }
              width="100%"
              textAlign="center"
              p="20px"
              justifyContent="center"
              onClick={() => this.setState({ isOpened: false })}
            >
              Sign up
            </Link>
            <Link
as={RouterLink}
              to="/logout"
              color="gray.500"
              fontSize="xl"
              display={this.props.user.isAuth ? 'flex' : 'none'}
              width="100%"
              textAlign="center"
              p="20px"
              justifyContent="center"
              onClick={() => this.setState({ isOpened: false })}
            >
              Logout
            </Link>
          </Box>
        </Box>
      </ChakraProvider>
    )
  }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        app: state.app,
        chat: state.chat,
        poll: state.poll,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        userActions: bindActionCreators(userActions, dispatch),
        appActions: bindActionCreators(appActions, dispatch),
        chatActions: bindActionCreators(chatActions, dispatch),
        pollActions: bindActionCreators(pollActions, dispatch),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withCookies(withRouter(App)))
