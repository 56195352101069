import React from 'react'
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import * as userActions from "../../../Redux/actions/user"
import * as appActions from "../../../Redux/actions/app"
import * as adminActions from "../../../Redux/actions/admin"
import { bindActionCreators } from "redux"
import { withCookies } from "react-cookie"
import adminChatApi from '../../../apis/admin/chat'
import appApi from '../../../apis/app'
import { 
    ChakraProvider, 
    Box, 
    Wrap, 
    WrapItem, 
    Text, Progress, 
    useToast, 
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuItemOption,
    MenuGroup,
    MenuOptionGroup,
    MenuDivider, 
} from '@chakra-ui/react'
import { EditIcon, CheckIcon, DeleteIcon, SettingsIcon } from '@chakra-ui/icons'


import EmptyPollsImg from '../../../assets/imgs/empty_polls.svg'
import ChatItem from './ChatItem'
import Chat from './Chat'
import qs from 'qs'

class App extends React.Component {
    state = {
        isCreatePollModal: false,
        activeChatId: qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).chatId
    }

    componentDidMount() {
        this.props.history.listen((location) => {
            this.setState({
                activeChatId: qs.parse(location.search, { ignoreQueryPrefix: true }).chatId,
            })
        })

        if(!this.props.admin.isGettedChats)
            adminChatApi.getChats().then(response => {
                if(response.success)
                    this.props.adminActions.getChats(response.chats)
            })
    }
    
    render() {
        return (<>
            <ChakraProvider resetCSS>
                <Box maxHeight='calc(100vh - 302px)' minHeight="calc(100vh - 302px)" position='relative' display="flex" gap='10px' maxWidth='1200px' m='0 auto'>
                    <Box boxShadow='0 2px 4px 0 rgb(0 0 0 / 4%)' backgroundColor="#fff" width='30%' minWidth='320px' overflowY='scroll' borderRadius="5px">
                        {this.props.admin.chats.map(chat => <ChatItem activeChatId={this.state.activeChatId} chat={chat} index={chat._id} />)}
                    </Box>

                    <Box position='relative' boxShadow='0 2px 4px 0 rgb(0 0 0 / 4%)' minWidth='320px' width='100%' borderRadius="5px" backgroundColor="#fff">
                        {this.props.admin.isGettedChats && <Chat activeChatId={this.state.activeChatId} />}
                    </Box>
                </Box>
            </ChakraProvider>
        </>)
  }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        app: state.app,
        admin: state.admin,
        chats: state.chats,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        userActions: bindActionCreators(userActions, dispatch),
        appActions: bindActionCreators(appActions, dispatch),
        adminActions: bindActionCreators(adminActions, dispatch),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withCookies(withRouter(App)))
