import React from 'react'
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import * as userActions from "../../../Redux/actions/user"
import * as appActions from "../../../Redux/actions/app"
import * as adminActions from "../../../Redux/actions/admin"
import { bindActionCreators } from "redux"
import { withCookies } from "react-cookie"
import adminPollApi from '../../../apis/admin/poll'
import adminChatApi from '../../../apis/admin/chat'
import appApi from '../../../apis/app'
import { 
    ChakraProvider, 
    Box, 
    Badge, 
    IconButton, 
    Text, Progress, 
    useToast, 
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuItemOption,
    MenuGroup,
    MenuOptionGroup,
    Avatar, 
} from '@chakra-ui/react'
import { EditIcon, CheckIcon, DeleteIcon, SettingsIcon } from '@chakra-ui/icons'

import { getAvatarColor } from '../../../Controllers/FunctionsController'


class App extends React.Component {
    openChat = () => {
        this.props.history.push({search: `tab=Chats&chatId=${this.props.chat._id}`})

        if(!this.props.admin.chats.find(chat => chat._id === this.props.chat._id).isGetted)
            adminChatApi.getChat({chatId: this.props.chat._id}).then(response => {
                if(response.success)
                    this.props.adminActions.getChat(this.props.chat._id, response.messages)
            })
    }

    render() {
        return (<>
            {!!this.props.chat.user && <ChakraProvider resetCSS>
                <Box boxShadow={this.props.activeChatId === this.props.chat._id ? '0 0px 4px 0 rgb(0 0 0 / 4%)' : 'none'} onClick={this.openChat} cursor='pointer' _hover={{ bg: '#ebedf0' }} display="flex" p='15px 10px' m='5px 0' flexWrap='no-wrap' alignItems='center' overflow='hidden' flexDirection='row'>
                    <Avatar size='sm' backgroundColor={getAvatarColor(this.props.chat.user.email || this.props.chat.user.uId)} mr='10px' />
                    <Box display='flex' flexDirection='column' width='100%' flexWrap='no-wrap' overflow='hidden'>
                        <Text 
                            fontWeight="bold" 
                            color='red.500'
                            textOverflow='ellipsis' 
                            overflow='hidden' 
                            whiteSpace='nowrap'
                            fontSize='sm'
                        >
                            {this.props.chat.user.email || this.props.chat.user.uId}
                        </Text>
                        <Box display='flex' alignItems='center'>
                            <Text 
                                color='gray.500' 
                                textOverflow='ellipsis' 
                                overflow='hidden' 
                                whiteSpace='nowrap'
                                width='100%'
                            >
                                {!!this.props.chat.lastMessage ? this.props.chat.lastMessage.text : 'No messages'}
                            </Text>
                            {this.props.chat.noRead > 0 && <Badge ml='1' colorScheme='red' variant='solid'>
                                {this.props.chat.noRead}
                            </Badge>}
                        </Box>
                    </Box>
                </Box>
            </ChakraProvider>}
        </>)
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        app: state.app,
        admin: state.admin,
        chats: state.chats,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        userActions: bindActionCreators(userActions, dispatch),
        appActions: bindActionCreators(appActions, dispatch),
        adminActions: bindActionCreators(adminActions, dispatch),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withCookies(withRouter(App)))
