import React from 'react'
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import * as userActions from "../../../../Redux/actions/user"
import * as appActions from "../../../../Redux/actions/app"
import * as adminActions from "../../../../Redux/actions/admin"
import { bindActionCreators } from "redux"
import { withCookies } from "react-cookie"
import adminPollApi from '../../../../apis/admin/poll'
import appApi from '../../../../apis/app'
import { 
    ChakraProvider, 
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    FormControl,
    FormLabel,
    Input,
    Button,
    IconButton,
    Checkbox
} from '@chakra-ui/react'
import { MinusIcon, AddIcon } from '@chakra-ui/icons'

class App extends React.Component {
    state = {
        question: '',
        isFreeAnswerAvailable: false,
        options: ['', ''],
        isFetching: false
    }    

    submit = () => {
        if(this.state.isFetching)
            return 

        this.setState({isFetching: true})
        
        let poll = {
            question: this.state.question,
            options: this.state.options.map((option, index) => {
                return {number: index+1, answer: option}
            }),
            isFreeAnswerAvailable: this.state.isFreeAnswerAvailable
        }

        adminPollApi.createPoll(poll).then(response => {
            if(response.success) {
                this.props.adminActions.addPoll(poll)
            }

            this.setState({isFetching: false})
        })
        
        this.setState({
            question: '',
            options: ['', ''],
            isFreeAnswerAvailable: false
        })
    }

    render() {
        return (
            <ChakraProvider resetCSS>
                <Modal
                    isOpen={this.props.isOpen}
                    onClose={this.props.onClose}
                >
                    <ModalOverlay />
                    <ModalContent minW='500px'>
                        <ModalHeader>Create new poll</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody pb={6}>
                            <FormControl>
                                <FormLabel>Question</FormLabel>
                                <Input onChange={(e) => {
                                    this.setState({
                                        question: e.target.value
                                    })
                                }} placeholder='Do you love cats?' />
                            </FormControl>

                            {this.state.options.map((option, index) => {
                                return <FormControl key={index} mt={4}>
                                    <FormLabel>Option #{index+1}</FormLabel>
                                    <Input value={option} placeholder='Yes' pr='50px' onChange={(e) => {
                                        this.setState({
                                            options: this.state.options.map((option, i) => i === index ? e.target.value : option)
                                        })
                                    }} />
                                    {this.state.options.length > 1 && <IconButton 
                                        position='absolute'
                                        top='32px'
                                        right='0'
                                        m='auto'
                                        colorScheme='red' 
                                        zIndex={999}
                                        icon={<MinusIcon />} 
                                        onClick={() => {
                                            this.setState({
                                                options: [ ...this.state.options.filter((option, i) => i !== index)]
                                            })
                                        }} 
                                    />}
                                </FormControl>
                            })}

                            {this.state.options.length < 12 && <Button mb='10px' colorScheme='blue' leftIcon={<AddIcon />} mt={'10px'} onClick={() => this.setState({options: [ ...this.state.options, '']})}>
                                Add option
                            </Button>}
                            <br/>
                            <Checkbox isChecked={this.state.isFreeAnswerAvailable} onChange={() => this.setState({isFreeAnswerAvailable: !this.state.isFreeAnswerAvailable})}>Is free answer available</Checkbox>
                        </ModalBody>
                        
                        <ModalFooter>
                            <Button colorScheme='green' onClick={this.submit} mr={3}>
                                Create
                            </Button>
                            <Button onClick={this.props.onClose}>Cancel</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </ChakraProvider>
        )
  }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        app: state.app,
        admin: state.admin,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        userActions: bindActionCreators(userActions, dispatch),
        appActions: bindActionCreators(appActions, dispatch),
        adminActions: bindActionCreators(adminActions, dispatch),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withCookies(withRouter(App)))
