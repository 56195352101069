import React from 'react'
import { withRouter, Link as RouterLink } from "react-router-dom"
import { connect } from "react-redux"
import * as userActions from "../Redux/actions/user"
import * as appActions from "../Redux/actions/app"
import * as chatActions from "../Redux/actions/chat"
import * as pollActions from "../Redux/actions/poll"
import { bindActionCreators } from "redux"
import { randomInteger, setTitle } from "../Controllers/FunctionsController"
import { withCookies } from "react-cookie"
import appApi from '../apis/app'
import authApi from '../apis/auth'
import chatApi from '../apis/chat'
import pollApi from '../apis/poll'
import Iframe from "../CommonComponents/Iframe"
import Message from "../Components/Message"
import Poll from "../Components/Poll"
import PollAnswer from "../Components/PollAnswer"
import { ChakraProvider, Box, Image, Text, Button } from '@chakra-ui/react'

class App extends React.Component {
    state = {

    }

  

    render() {
    return (
      <ChakraProvider resetCSS>
        <Box
          p="30px"
          display="flex"
          justifyContent="flex-start"
          alignItems="flex-start"
          flexDirection="column"
          boxShadow="0px 0px 10px -5px #000"
          backgroundColor="#ffffff"
          ml="50px"
          mr="50px"
          mb="20px"
          borderRadius="15px"
        >
          <Box display="flex" justifyContent="flex-start" alignItems="center">
            <Image
              height="100px"
              width="100px"
              src="https://api.liveframe.eu/media/onxFgL1r19jWBo9aVrZHAIRr.png"
              mr="25px"
            />
            <Box>
              <Text fontWeight="bold" mb="15px">
                Acest site utilizează cookie-uri
              </Text>
              <Text>
                Acest website foloseste ‘cookies’ pentru a va oferi
                funcționalitați necesare și o experiența online îmbunatațita.
                Prin continuarea utilizarii acestui website, va exprimați
                acordul fața de ‘cookies’.
              </Text>
            </Box>
          </Box>
          <Box display="flex" width="100%" justifyContent="flex-end" mt="25px">
            <Button
              variant="solid"
              size="md"
              colorScheme="gray"
              onClick={this.props.declineCookie}
            >
              Declin
            </Button>
            <Button
              variant="solid"
              size="md"
              ml="20px"
              colorScheme="#e30073"
              onClick={this.props.acceptCookie}
              backgroundColor="#e30073"
              color="#ffffff"
            >
              Accept
            </Button>
          </Box>
        </Box>
      </ChakraProvider>
    )
  }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        app: state.app,
        chat: state.chat,
        poll: state.poll,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        userActions: bindActionCreators(userActions, dispatch),
        appActions: bindActionCreators(appActions, dispatch),
        chatActions: bindActionCreators(chatActions, dispatch),
        pollActions: bindActionCreators(pollActions, dispatch),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withCookies(withRouter(App)))
