import React from 'react'
import { withRouter, Link as RouterLink } from "react-router-dom"
import { connect } from "react-redux"
import * as userActions from "../Redux/actions/user"
import * as appActions from "../Redux/actions/app"
import * as chatActions from "../Redux/actions/chat"
import * as pollActions from "../Redux/actions/poll"
import { bindActionCreators } from "redux"
import { randomInteger, setTitle } from "../Controllers/FunctionsController"
import { withCookies } from "react-cookie"
import appApi from '../apis/app'
import authApi from '../apis/auth'
import chatApi from '../apis/chat'
import pollApi from '../apis/poll'
import Iframe from "../CommonComponents/Iframe"
import Message from "../Components/Message"
import Poll from "../Components/Poll"
import PollAnswer from "../Components/PollAnswer"
import {
  ChakraProvider,
  Box,
  IconButton,
  FormControl,
  FormLabel,
  Textarea,
  Button
} from '@chakra-ui/react'
import { ChatIcon, EditIcon, ArrowRightIcon } from '@chakra-ui/icons'

class App extends React.Component {
    state = {
        activeTab: "chat",
        text: "",
        src: "https://cdn.jwplayer.com/players/I4c5CBcg-aDK3pltT.html",
    }

  componentDidMount() {
        if(!this.props.chat.isGetted)
            chatApi.getChat({userId: this.props.user._id}).then((response) => {
                if(response.success)
                      this.props.chatActions.getChat(response.messages)
            })
    }
    
    sendMessage = () => {
          if(!/S/.test(this.state.text))
          return
      
        chatApi.sendMessage({text: this.state.text})
      
          this.props.chatActions.sendMessage(this.state.text)
      
        this.setState({text: ''})
    }
    
    onChangeTextarea = (e) => {
        this.setState({text: e.target.value})
    }
    
    getMessages = () => {
        return this.props.chat.messages.map(message => <Message message={message} key={message.createdAt} />)
    }

    render() {
    return (
      <ChakraProvider resetCSS>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexWrap="wrap"
          p="0 25px"
          m="50px 0"
          flexDirection="column"
        >
          <Box>
            <Button
              variant="solid"
              size="md"
              backgroundColor="#e30073"
              color="#ffffff"
              m="10px"
              onClick={() =>
                this.setState({
                  src: 'https://cdn.jwplayer.com/players/I4c5CBcg-aDK3pltT.html'
                })
              }
            >
              ENG
            </Button>
            <Button
              variant="solid"
              size="md"
              backgroundColor="#e30073"
              color="#ffffff"
              m="10px"
              onClick={() =>
                this.setState({
                  src: 'https://cdn.jwplayer.com/players/G16xuDQE-aDK3pltT.html'
                })
              }
            >
              ORG
            </Button>
            <Button
              variant="solid"
              size="md"
              backgroundColor="#e30073"
              color="#ffffff"
              m="10px"
              onClick={() =>
                this.setState({
                  src: 'https://cdn.jwplayer.com/players/m7j6EwLg-aDK3pltT.html'
                })
              }
            >
              ROU
            </Button>
          </Box>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Iframe
              src={this.state.src}
              backgroundColor="#fff"
              minWidth="350px"
              width="900px"
              id="live-video"
              overflow="visible"
              maxWidth="100%"
              maxHeight="%"
              borderRadius="5px"
              boxShadow="0 2px 4px 0 rgb(0 0 0 / 4%)"
            />
            <Box
              ml="15px"
              minWidth="350px"
              maxWidth="100%"
              mr="15px"
              mt="10px"
              mb="10px"
              display={
                this.props.app.isEnabledPolls || this.props.app.isEnabledChat
                  ? 'flex'
                  : 'none'
              }
              flexDirection="column"
            >
              <Box
                mb="10px"
                display={this.state.activeTab === 'chat' ? 'block' : 'none'}
                flexDirection="column"
              >
                <FormControl
                  p="15px"
                  backgroundColor="red.500"
                  borderRadius="5px"
                  boxShadow="0 2px 4px 0 rgb(0 0 0 / 4%)"
                >
                  <FormLabel color="#ffffff">Pune o intrebare</FormLabel>
                  <Box display="flex">
                    <Textarea
                      backgroundColor="#ffffff"
                      border="0px"
                      rows={1}
                      minHeight="40px"
                      onChange={this.onChangeTextarea}
                      value={this.state.text}
                    />
                    <IconButton
                      aria-label="icon"
                      icon={<ArrowRightIcon />}
                      size="md"
                      color="red.500"
                      backgroundColor="#ffffff"
                      borderRadius="50px"
                      p={0}
                      fontSize="sm"
                      ml="5px"
                      onClick={this.sendMessage}
                    />
                  </Box>
                </FormControl>
                <Box
                  height="340px"
                  mt="5px"
                  borderRadius="5px"
                  p="0 15px"
                  overflow="visible"
                  overflowY="scroll"
                  maxWidth="350px"
                  boxShadow="0 2px 4px 0 rgb(0 0 0 / 4%)"
                  backgroundColor="#fff"
                >
                  {this.getMessages()}
                </Box>
              </Box>
              <Box
                display={
                  this.props.app.isEnabledPolls && this.props.app.isEnabledChat
                    ? 'flex'
                    : 'none'
                }
                flexDirection="column"
              >
                <IconButton
                  aria-label="icon"
                  icon={<ChatIcon />}
                  size="md"
                  borderRadius="100px"
                  variant="solid"
                  colorScheme="gray"
                  width="50px"
                  height="50px"
                  onClick={() => this.setState({ activeTab: 'chat' })}
                  display={this.state.activeTab === 'poll' ? 'flex' : 'none'}
                  boxShadow="0 2px 4px 0 rgb(0 0 0 / 4%)"
                />
                <IconButton
                  aria-label="icon"
                  icon={<EditIcon />}
                  size="md"
                  borderRadius="100px"
                  variant="solid"
                  colorScheme="gray"
                  width="50px"
                  height="50px"
                  onClick={() => this.setState({ activeTab: 'poll' })}
                  display={this.state.activeTab === 'chat' ? 'flex' : 'none'}
                  boxShadow="0 2px 4px 0 rgb(0 0 0 / 4%)"
                />
              </Box>
              <Box
                pt="10px"
                display={this.state.activeTab === 'poll' ? 'block' : 'none'}
                mt="10px"
              >
                <Poll />
              </Box>
            </Box>
          </Box>
        </Box>
      </ChakraProvider>
    )
  }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        app: state.app,
        chat: state.chat,
        poll: state.poll,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        userActions: bindActionCreators(userActions, dispatch),
        appActions: bindActionCreators(appActions, dispatch),
        chatActions: bindActionCreators(chatActions, dispatch),
        pollActions: bindActionCreators(pollActions, dispatch),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withCookies(withRouter(App)))
