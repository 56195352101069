import io from 'socket.io-client'
import store from '../Redux/store'
import {
    ADMIN_ADD_MESSAGE,
    ADD_CHAT,
    ADD_MESSAGE,
    NEW_POLL,
    REMOVE_POLL,
    ADMIN_REMOVE_POLL,
    ADMIN_ADD_ANSWER,
    ADMIN_READ,
    CHANGE_POLL_STATUS
} from '../Redux/constants'

let socket = false

export default { 
    init: (apiToken) => {
        if(socket) 
            return

        socket = io(process.env.REACT_APP_API_URL, {transports: ['websocket', 'polling', 'flashsocket']})

        socket.on('connect', () => {
            socket.emit('auth', apiToken)
        })

        socket.on('messageFromAdmin', ({message}) => {
            store.dispatch({
                type: ADD_MESSAGE,
                payload: message
            })
        })

        socket.on('messageFromUser', ({message}) => {
            if(store.getState().admin.chats.find(x => x._id === message.chat)) {
                store.dispatch({
                    type: ADMIN_ADD_MESSAGE,
                    payload: {message, chatId: message.chat}
                })
            } else {
                let chat = {
                    _id: message.chat,
                    lastMessage: message,
                    messages: [message],
                    user: message.user,
                    noRead: 1,
                    updatedAt: Date.now(),
                    createdAt: Date.now()
                }
                
                store.dispatch({
                    type: ADD_CHAT,
                    payload: chat
                })
            }
        })

        socket.on('readMessages', (chatId) => {
            store.dispatch({
                type: ADMIN_READ,
                payload: chatId
            })
        })

        socket.on('newPoll', poll => {
            store.dispatch({
                type: NEW_POLL,
                payload: poll
            })
        })

        socket.on('removePoll', pollId => {
            store.dispatch({
                type: REMOVE_POLL,
                payload: pollId
            })
        })

        socket.on('removePollForAdmin', question => {
            store.dispatch({
                type: ADMIN_REMOVE_POLL,
                payload: question
            })
        })

        socket.on('votePollForAdmin', answer => {
            store.dispatch({
                type: ADMIN_ADD_ANSWER,
                payload: answer
            })
        })

        socket.on('enablePollForAdmin', question => {
            store.dispatch({
                type: CHANGE_POLL_STATUS,
                payload: {question, isEnabled: true}
            })
        })

        socket.on('disablePollForAdmin', question => {
            store.dispatch({
                type: CHANGE_POLL_STATUS,
                payload: {question, isEnabled: false}
            })
        })
    },
    getSocketId: () => {
        return socket.id
    },
}

