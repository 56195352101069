import { handleError, handleSuccess } from "."
import CookieController from "../Controllers/CookieController"

let apiUrl = `${process.env.REACT_APP_API_URL}/api/${process.env.REACT_APP_API_VERSION}/app/user/`

const apis = {
    getUser: function(params = {}) {
        params.projectId = process.env.REACT_APP_PROJECT_ID
        params.uId = CookieController.get('uId')
        let searchParameters = new URLSearchParams()
            
        Object.keys(params).forEach(function(parameterName) {
            searchParameters.append(parameterName, params[parameterName])
        })

        return fetch(`${apiUrl}me?${searchParameters}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${CookieController.get('appToken')}`
            },
        })
        .then(handleSuccess)
        .catch(e => {
            console.error('user api "getUser" error:', e)
            return handleError()
        })
    },
}

export default apis