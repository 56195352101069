
import React from 'react'
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import * as userActions from "../../../Redux/actions/user"
import * as appActions from "../../../Redux/actions/app"
import * as adminActions from "../../../Redux/actions/admin"
import { bindActionCreators } from "redux"
import { withCookies } from "react-cookie"
import adminStatApi from '../../../apis/admin/stat'
import appApi from '../../../apis/app'
import { getRandomColor } from '../../../Controllers/FunctionsController'
import { 
    ChakraProvider, 
    Box, 
    Button, 
    IconButton, 
    Text, Progress, 
    useToast, 
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Badge,
    Input,
} from '@chakra-ui/react'
import { MinusIcon, CheckIcon, DeleteIcon, SettingsIcon } from '@chakra-ui/icons'

import Chart from 'chart.js/auto';

Date.prototype.toDateInputValue = (function() {
    let local = new Date(this);
    local.setMinutes(this.getMinutes() - this.getTimezoneOffset());
    return local.toJSON().slice(0,10);
});

let myChart = false

class App extends React.Component {
    state = {
        isFetching: true,
        date: new Date().toDateInputValue()
    }

    getStat() {
        this.setState({isFetching: true})

        adminStatApi.getGeoStat({
            date: this.state.date
        }).then(response => {
            if(response.success) {
                this.renderChart(response.data)
            }

            this.setState({isFetching: false})
        })
    }

    componentDidMount() {
        if(!!myChart) {
            myChart.destroy()
            myChart = false
        }
        this.getStat()
        this.props.setDate(this.state.date)
    }

    renderChart(datapoints) {
        let colors = []
        let labels = []
        let values = []

        for(let i = 0;i < datapoints.length;i++) {
            colors.push(datapoints[i].color)
            labels.push(`${datapoints[i].country}` + (!!datapoints[i].city.length ? ` / ${datapoints[i].city}` : ''))
            values.push(datapoints[i].count)
        }

        if(datapoints.length === 0) {
            colors.push('#eeeeee')
            labels.push(`None`)
            values.push(1)
        }

        const data = {
            labels: labels,
            datasets: [
                {
                    label: 'Dataset 1',
                    data: values,
                    backgroundColor: colors,
                }
            ]
        };

        if(!!myChart) {
            myChart.data = data
            myChart.update()
        } else {
            const ctx = document.getElementById(`geoChart`)

            myChart = new Chart(ctx, {
                type: 'pie',
                data: data,
                options: {
                    responsive: true,
                    plugins: {
                        legend: {
                            position: 'right',
                            labels: {
                                usePointStyle: true,
                            }
                        },
                        title: {
                            display: false,
                        }
                    }
                }
            })
        }
    }
    render() {
        return (<>
            <ChakraProvider resetCSS>
                <Box
                    maxWidth="100%"
                    width='lg'
                    minWidth="lg"
                    boxShadow="0 7px 14px 0 rgb(0 0 0 / 7%)"
                    backgroundColor="#ffffff"
                    borderRadius="5px"
                    p="15px"
                    mr="8px"
                    ml="8px"
                    mt="16px"
                    height='max-content'
                >
                    <Text fontWeight='bold' mb={'15px'} fontSize='xl' color='#718096'>Users by Regions</Text>

                    <Input value={this.state.date} onChange={e => {
                        this.setState({date: e.target.value}, () => {
                            this.getStat()
                            this.props.setDate(e.target.value)
                        })
                    }} width='300px' mb={'15px'} placeholder='Date' type='date' />

                    <canvas id={`geoChart`} className="chart-geo"></canvas>
                </Box>
            </ChakraProvider>
        </>)
  }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        app: state.app,
        admin: state.admin,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        userActions: bindActionCreators(userActions, dispatch),
        appActions: bindActionCreators(appActions, dispatch),
        adminActions: bindActionCreators(adminActions, dispatch),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withCookies(withRouter(App)))

