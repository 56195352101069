import React from 'react'
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import * as userActions from "../../../Redux/actions/user"
import * as appActions from "../../../Redux/actions/app"
import * as adminActions from "../../../Redux/actions/admin"
import { bindActionCreators } from "redux"
import { withCookies } from "react-cookie"
import adminPollApi from '../../../apis/admin/poll'
import appApi from '../../../apis/app'
import { 
    ChakraProvider, 
    Box, 
    Button, 
    IconButton, 
    Text, Progress, 
    useToast, 
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Badge,
    MenuGroup,
    MenuOptionGroup,
    MenuDivider, 
} from '@chakra-ui/react'
import { MinusIcon, CheckIcon, DeleteIcon, SettingsIcon } from '@chakra-ui/icons'

import CreatePollModal from './Modals/CreatePoll'
import FreeAnswersModal from './Modals/FreeAnswers'

import EmptyPollsImg from '../../../assets/imgs/empty_polls.svg'

const Poll = (props) => {
    const toast = useToast()

    return <Box
        maxWidth="sm"
        minWidth="sm"
        boxShadow="0 7px 14px 0 rgb(0 0 0 / 7%)"
        backgroundColor="#ffffff"
        borderRadius="5px"
        p="15px"
        mr="8px"
        ml="8px"
        mt="16px"
    >
        <Box
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
        >
            <Text fontWeight="bold" fontSize="xl" color="gray.500">
                {props.poll.question} <Badge ml='5px' fontSize='xs' fontWeight='normal'>{props.poll.count} votes</Badge>
            </Text>
            <Box>
                {props.poll.isEnabled && <Badge mr='5px' colorScheme='green'>Enabled</Badge>}
                {!props.poll.isEnabled && <Badge mr='5px' colorScheme='red'>Disabled</Badge>}
                <Menu>
                    <MenuButton as={IconButton} size='sm' icon={<SettingsIcon />}>
                    </MenuButton>
                    <MenuList>
                        <MenuItem icon={<DeleteIcon />} onClick={() => props.removePoll(props.poll.question)}>Delete</MenuItem>
                        {!props.poll.isEnabled && <MenuItem onClick={() => props.enablePoll(props.poll.question)} icon={<CheckIcon />}>Enable</MenuItem>}
                        {props.poll.isEnabled && <MenuItem onClick={() => props.disablePoll(props.poll.question)} icon={<MinusIcon />}>Disable</MenuItem>}
                    </MenuList>
                </Menu>
            </Box>
        </Box>
        {props.poll.options.map((option, index) => {
            let percent = (100 / props.poll.count * option.count) || 0

            return <Box key={index} mt='15px'>
                <Text fontWeight="bold" color="gray.500" fontSize="sm">
                    {option.answer}
                </Text>
                <Progress
                    value={percent}
                    max={100}
                    size="sm"
                    colorScheme="red"
                    borderRadius="5px"
                    mt="10px"
                    height="4px"
                />
                <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                >
                <Text fontSize="xs" color="gray.500">
                    {option.count} votes
                </Text>
                <Text fontWeight="bold" color="gray.500">
                    {Math.floor(percent)}%
                </Text>
                </Box>
            </Box>
        })}
        {props.poll.isFreeAnswerAvailable && <Button mt='10px' colorScheme="gray" onClick={() => props.onOpenAnswers(props.poll._id)}>Show free answers</Button>}
    </Box>
}

class App extends React.Component {
    state = {
        isCreatePollModal: false,
        isFreeAnswersModal: false,
    }

    componentDidMount() {
        if(!this.props.admin.isGettedPolls)
            adminPollApi.getPolls().then(response => {
                if(response.success)
                    this.props.adminActions.getPolls(response.polls)
            })
    }

    removePoll(question) {
        adminPollApi.removePoll({question})
        this.props.adminActions.removePoll(question)
    }

    togglePoll(question, isEnabled) {
        adminPollApi.togglePoll({question, isEnabled})
        this.props.adminActions.togglePoll(question, isEnabled)
    }

    render() {
        return (<>
            <ChakraProvider resetCSS>
                <Box minHeight="calc(100vh - 302px)" position='relative'>
                    <Button colorScheme="red" onClick={() => this.setState({isCreatePollModal: true})}>Create Poll</Button>
                    <Box display="flex" flexWrap="wrap" justifyContent="center">
                        {this.props.admin.polls.map(poll => <Poll 
                        onOpenAnswers={(pollId) => {
                            this.setState({isFreeAnswersModal: pollId})
                        }} 
                        removePoll={(question) => this.removePoll(question)} 
                        disablePoll={(question) => this.togglePoll(question, false)} 
                        enablePoll={(question) => this.togglePoll(question, true)} 
                        poll={poll} 
                        index={poll.title} 
                        />)}

                        {this.props.admin.polls.length === 0 && <Box
                            position='absolute'
                            top='0'
                            right='0'
                            bottom='0'
                            left='0'
                            m='auto'
                            width='max-content'
                            height='max-content'
                            display='flex'
                            flexDirection='column'
                            justifyContent='center'
                            alignItems='center'
                            p='20px'
                        >
                            <img src={EmptyPollsImg} width={'300px'} />
                            <Text
                                fontWeight="bold"
                                color="gray.500"
                                textAlign="center"
                                fontSize="2xl"
                                mb='10px'
                            >
                                You haven’t created any polls yet
                            </Text>
                            <Button colorScheme="red" onClick={() => this.setState({isCreatePollModal: true})}>Create Poll</Button>
                        </Box>}
                    </Box>
                </Box>
            </ChakraProvider>
            
            {!!this.state.isFreeAnswersModal && <FreeAnswersModal pollId={this.state.isFreeAnswersModal} onClose={() => this.setState({isFreeAnswersModal: false})} />}
            <CreatePollModal isOpen={this.state.isCreatePollModal} onClose={() => this.setState({isCreatePollModal: false})} />
        </>)
  }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        app: state.app,
        admin: state.admin,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        userActions: bindActionCreators(userActions, dispatch),
        appActions: bindActionCreators(appActions, dispatch),
        adminActions: bindActionCreators(adminActions, dispatch),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withCookies(withRouter(App)))
