
import React from 'react'
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import * as userActions from "../../../Redux/actions/user"
import * as appActions from "../../../Redux/actions/app"
import * as adminActions from "../../../Redux/actions/admin"
import { bindActionCreators } from "redux"
import { withCookies } from "react-cookie"
import adminStatApi from '../../../apis/admin/stat'
import appApi from '../../../apis/app'
import { 
    ChakraProvider, 
    Box, 
    Button, 
    IconButton, 
    Text, Progress, 
    useToast, 
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Badge,
    Input,
    Stat,
    StatLabel,
    StatNumber,
    StatHelpText,
    StatArrow,
    StatGroup,
} from '@chakra-ui/react'
import { MinusIcon, CheckIcon, DeleteIcon, SettingsIcon } from '@chakra-ui/icons'

class App extends React.Component {
    state = {
        isFetching: true,
        data: {totalUsers: 0, onlineUsers: 0, totalLogins: 0}
    }

    getStat() {
        this.setState({isFetching: true})

        adminStatApi.getCommonStat().then(response => {
            if(response.success) {
                this.setState({data: response.data})
                this.props.setStat(response.data)
            }

            this.setState({isFetching: false})
        })
    }

    componentDidMount() {
        this.getStat()
    }

    render() {
        return (<>
            <ChakraProvider resetCSS>
                <Box
                    maxWidth="100%"
                    width='container.xl'
                    boxShadow="0 7px 14px 0 rgb(0 0 0 / 7%)"
                    backgroundColor="#ffffff"
                    borderRadius="5px"
                    p="15px"
                    mr="8px"
                    ml="8px"
                    mt="16px"
                    height='max-content'
                >
                    <Text fontWeight='bold' mb={'15px'} fontSize='xl' color='#718096'>Main info</Text>
                    <StatGroup color='#718096'>
                        <Stat>
                            <StatLabel>Total users</StatLabel>
                            <StatNumber>{this.state.data.totalUsers}</StatNumber>
                        </Stat> 
                        <Stat>
                            <StatLabel>Online now</StatLabel>
                            <StatNumber>{this.state.data.onlineUsers}</StatNumber>
                        </Stat>
                        <Stat>
                            <StatLabel>Logins</StatLabel>
                            <StatNumber>{this.state.data.totalLogins}</StatNumber>
                        </Stat>
                    </StatGroup>
                </Box>
            </ChakraProvider>
        </>)
  }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        app: state.app,
        admin: state.admin,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        userActions: bindActionCreators(userActions, dispatch),
        appActions: bindActionCreators(appActions, dispatch),
        adminActions: bindActionCreators(adminActions, dispatch),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withCookies(withRouter(App)))

