import React from 'react'
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import * as userActions from "../Redux/actions/user"
import * as appActions from "../Redux/actions/app"
import { bindActionCreators } from "redux"
import { withCookies } from "react-cookie"
import appApi from '../apis/app'
import { ChakraProvider, Box, Button, Badge } from '@chakra-ui/react'
import { EditIcon, ViewIcon, ChatIcon } from '@chakra-ui/icons'
import Polls from '../CommonComponents/Dashboard/Polls'
import Chats from '../CommonComponents/Dashboard/Chats'
import Statistics from '../CommonComponents/Dashboard/Statistic'
import qs from 'qs'

class App extends React.Component {
    state = {
        activeTab: qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).tab || 'Statistics'
    }
    
    componentDidMount() {
        this.props.history.listen((location) => {
            this.setState({
                activeTab: qs.parse(location.search, { ignoreQueryPrefix: true }).tab,
            })
        })
    }

    render() {
        return (
            <ChakraProvider resetCSS>
                <Box pl="50px" pr="50px">
                    <Box
                    backgroundColor="#ffffff"
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-start"
                    borderRadius="20px"
                    boxShadow="0 2px 4px 0 rgb(0 0 0 / 4%)"
                    p="20px"
                    mb="20px"
                    mt="20px"
                    >
                        <Button
                            variant="solid"
                            size="lg"
                            leftIcon={<ViewIcon />}
                            color={this.state.activeTab === 'Statistics' ? 'red.500' : 'gray.500'}
                            backgroundColor="#ffffff"
                            ml="10px"
                            mr="10px"
                            border={this.state.activeTab === 'Statistics' ? '2px' : 'solid 2px #fff'}
                            onClick={() => this.props.history.push({search: `tab=Statistics`})}
                        >
                            Statistics
                        </Button>
                        <Button
                            position='relative'
                            variant="solid"
                            size="lg"
                            leftIcon={<ChatIcon />}
                            color={this.state.activeTab === 'Chats' ? 'red.500' : 'gray.500'}
                            backgroundColor="#ffffff"
                            ml="10px"
                            mr="10px"
                            border={this.state.activeTab === 'Chats' ? '2px' : 'solid 2px #fff'}
                            onClick={() => this.props.history.push({search: `tab=Chats`})}
                        >
                            Chats
                            {!!this.props.admin.chats.filter(chat => !!chat.noRead).length && <Badge 
                                ml='1' 
                                colorScheme='red' 
                                variant='solid'
                                position='absolute'
                                right='0'
                                top='0'
                                transform='translate(50%, -50%)'
                                border='3px solid #fff'
                            >
                                {this.props.admin.chats.filter(chat => !!chat.noRead).length}
                            </Badge>}
                        </Button>
                        <Button
                            variant="solid"
                            size="lg"
                            leftIcon={<EditIcon />}
                            color={this.state.activeTab === 'Polls' ? 'red.500' : 'gray.500'}
                            backgroundColor="#ffffff"
                            ml="10px"
                            mr="10px"
                            border={this.state.activeTab === 'Polls' ? '2px' : 'solid 2px #fff'}
                            onClick={() => this.props.history.push({search: `tab=Polls`})}
                        >
                            Polls
                        </Button>
                    </Box>

                    {this.state.activeTab === 'Polls' && <Polls />}
                    {this.state.activeTab === 'Chats' && <Chats />}
			{this.state.activeTab === 'Statistics' && <Statistics />}
                </Box>
            </ChakraProvider>
        )
  }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        app: state.app,
        admin: state.admin
    }
}

function mapDispatchToProps(dispatch) {
    return {
        userActions: bindActionCreators(userActions, dispatch),
        appActions: bindActionCreators(appActions, dispatch),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withCookies(withRouter(App)))
