import { 
    ADD_MESSAGE,
    GET_MESSAGES
} from '../constants'
import store from '../store';

export const getChat = (messages) => (dispatch) => {
    dispatch({
        type: GET_MESSAGES,
        payload: messages
    })
}

export const sendMessage = (text) => (dispatch) => {
    let message = {
        text: text
            .replace(/(^\s*(?!.+)\n+)|(\n+\s+(?!.+)$)/g, "")
            .replace(/(\r\n|\r|\n){2,}/g, '$1\n'),
        user: store.getState().user,
        createdAt: Date.now(),
    }
    
    dispatch({
        type: ADD_MESSAGE,
        payload: message
    })
}