import React from 'react'
import { withRouter, Link as RouterLink } from "react-router-dom"
import { connect } from "react-redux"
import * as userActions from "../Redux/actions/user"
import * as appActions from "../Redux/actions/app"
import * as chatActions from "../Redux/actions/chat"
import * as pollActions from "../Redux/actions/poll"
import { bindActionCreators } from "redux"
import { randomInteger, setTitle } from "../Controllers/FunctionsController"
import { withCookies } from "react-cookie"
import appApi from '../apis/app'
import authApi from '../apis/auth'
import chatApi from '../apis/chat'
import pollApi from '../apis/poll'
import Iframe from "../CommonComponents/Iframe"
import Message from "../Components/Message"
import Poll from "../Components/Poll"
import PollAnswer from "../Components/PollAnswer"
import { ChakraProvider, Box, Avatar, Text } from '@chakra-ui/react'

class App extends React.Component {
    state = {

    }

  getName() {
    if(this.props.user._id === this.props.message.user._id)
      return 'Mă'
  	else
      return 'Admin'
}

getMessage() {
	return this.props.message.text
}

    render() {
    return (
      <ChakraProvider resetCSS>
        <Box display="flex" m="7px 0" maxWidth="100%">
          <Avatar backgroundColor="red.500" size="sm" />
          <Box ml="10px" width="100%">
            <Text color="red.500" fontWeight="bold" fontSize="sm">
              {this.getName()}
            </Text>
            <Text color="gray.500" overflowWrap="wrap" maxWidth="100%">
              {this.getMessage()}
            </Text>
          </Box>
        </Box>
      </ChakraProvider>
    )
  }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        app: state.app,
        chat: state.chat,
        poll: state.poll,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        userActions: bindActionCreators(userActions, dispatch),
        appActions: bindActionCreators(appActions, dispatch),
        chatActions: bindActionCreators(chatActions, dispatch),
        pollActions: bindActionCreators(pollActions, dispatch),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withCookies(withRouter(App)))
