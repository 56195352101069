import React from 'react'
import { withRouter, Link as RouterLink } from "react-router-dom"
import { connect } from "react-redux"
import * as userActions from "../Redux/actions/user"
import * as appActions from "../Redux/actions/app"
import * as chatActions from "../Redux/actions/chat"
import * as pollActions from "../Redux/actions/poll"
import { bindActionCreators } from "redux"
import { randomInteger, setTitle } from "../Controllers/FunctionsController"
import { withCookies } from "react-cookie"
import appApi from '../apis/app'
import authApi from '../apis/auth'
import chatApi from '../apis/chat'
import pollApi from '../apis/poll'
import Iframe from "../CommonComponents/Iframe"
import Message from "../Components/Message"
import Poll from "../Components/Poll"
import PollAnswer from "../Components/PollAnswer"
import { ChakraProvider, Box, Text, Link } from '@chakra-ui/react'

class App extends React.Component {
    state = {
        timer: 0,
        time: new Date().getTime() / 1000,
        status: "wait",
    }

  getTime() {
        let second = 1,
      minute = second * 60,
      hour = minute * 60,
      day = hour * 24
      
      let days = Math.floor(this.state.timer / day),
      hours = Math.floor((this.state.timer - (days * day)) / hour),
      minutes = Math.floor((this.state.timer - (days * day) - (hours * hour)) / minute),
      seconds = Math.floor((this.state.timer - (days * day) - (hours * hour) - (minutes * minute)) / second)
  
      return {days, hours, minutes, seconds}
  }

updateTimerInterval = false

secretCode = 'neotestevent'
indexOfSecret = 0

checkCode = (e) => {
        if(!!this.secretCode[this.indexOfSecret] && this.secretCode[this.indexOfSecret] === e.key) {
            this.indexOfSecret = this.indexOfSecret + 1

            if(this.indexOfSecret === this.secretCode.length) {
                this.toLive()
            }
        } else {
            this.indexOfSecret = 0
        }
    }

toLive() {
  this.setState({status: 'live'})
        clearInterval(this.updateTimerInterval)
      if(this.props.user.isAuth)
        this.props.history.push('/event')
}

updateTimer() {
  let statuses = [
    {
        range: [1, this.props.app.timer/1000]
    }]
    let isLive = true

    statuses.map((status, index) => {
        if(this.state.time > (status.range[0]) && this.state.time < status.range[1]-1) {
            isLive = false
            this.setState({
                timer: (status.range[1] - (new Date().getTime() / 1000)),
                time: new Date().getTime() / 1000,
            })
        }
    })

    if(isLive) {
      this.toLive()
    }
}

componentDidMount() {
  window.addEventListener('keypress', this.checkCode.bind(this))
  
    this.updateTimer()

    this.updateTimerInterval = setInterval(() => {
            this.updateTimer()
    }, 1000)
}

componentWillUnmount() {
  window.removeEventListener('keypress', this.checkCode.bind(this))
    clearInterval(this.updateTimerInterval)
    this.updateTimerInterval = false
}

    render() {
    return (
      <ChakraProvider resetCSS>
        <Box
          display={this.state.status === 'live' ? 'flex' : 'none'}
          justifyContent="center"
        >
          <Text
            textAlign="center"
            color="gray.500"
            fontWeight="bold"
            fontSize="4xl"
          >
            Telekom Mobile Townhall
          </Text>
        </Box>
        <Box
          display={this.state.status === 'wait' ? 'flex' : 'none'}
          justifyContent="center"
          flexWrap="wrap"
        >
          <Box pl="10px" pr="10px">
            <Box
              width="125px"
              height="125px"
              borderRadius="100px"
              backgroundColor="#e30073"
              display="flex"
              justifyContent="center"
              alignItems="center"
              overflow="hidden"
            >
              <Text
                color="#ffffff"
                fontWeight="bold"
                textAlign="center"
                fontSize="3xl"
              >
                {this.getTime().days}
              </Text>
            </Box>
            <Text textAlign="center" mt="10px" color="gray.500">
              Days
            </Text>
          </Box>
          <Box pl="10px" pr="10px">
            <Box
              width="125px"
              height="125px"
              borderRadius="100px"
              backgroundColor="#e30073"
              display="flex"
              justifyContent="center"
              alignItems="center"
              overflow="hidden"
            >
              <Text
                color="#ffffff"
                fontWeight="bold"
                textAlign="center"
                fontSize="3xl"
              >
                {this.getTime().hours}
              </Text>
            </Box>
            <Text textAlign="center" mt="10px" color="gray.500">
              Hours
            </Text>
          </Box>
          <Box pl="10px" pr="10px">
            <Box
              width="125px"
              height="125px"
              borderRadius="100px"
              backgroundColor="#e30073"
              display="flex"
              justifyContent="center"
              alignItems="center"
              overflow="hidden"
            >
              <Text
                color="#ffffff"
                fontWeight="bold"
                textAlign="center"
                fontSize="3xl"
                overflow="visible"
              >
                {this.getTime().minutes}
              </Text>
            </Box>
            <Text textAlign="center" mt="10px" color="gray.500">
              Minutes
            </Text>
          </Box>
          <Box pl="10px" pr="10px">
            <Box
              width="125px"
              height="125px"
              borderRadius="100px"
              backgroundColor="#e30073"
              display="flex"
              justifyContent="center"
              alignItems="center"
              overflow="hidden"
            >
              <Text
                color="#ffffff"
                fontWeight="bold"
                textAlign="center"
                fontSize="3xl"
              >
                {this.getTime().seconds}
              </Text>
            </Box>
            <Text textAlign="center" mt="10px" color="gray.500">
              Seconds
            </Text>
          </Box>
        </Box>
        <Box display={!this.props.user.isAuth ? 'flex' : 'none'} height="60px">
          <Link
as={RouterLink}
            to="/login"
            p="17px"
            borderRadius="5px"
            justifyContent="center"
            alignItems="center"
            m="0 auto"
            width="300px"
            height="60px"
            fontWeight="bold"
            textAlign="center"
            backgroundColor="#e30073"
            color="#ffffff"
            display="flex"
            boxShadow="0 7px 14px 0 rgb(0 0 0 / 7%)"
          >
            Sign in
          </Link>
        </Box>
      </ChakraProvider>
    )
  }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        app: state.app,
        chat: state.chat,
        poll: state.poll,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        userActions: bindActionCreators(userActions, dispatch),
        appActions: bindActionCreators(appActions, dispatch),
        chatActions: bindActionCreators(chatActions, dispatch),
        pollActions: bindActionCreators(pollActions, dispatch),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withCookies(withRouter(App)))
