import React from 'react'
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import * as userActions from "../../../Redux/actions/user"
import * as appActions from "../../../Redux/actions/app"
import * as adminActions from "../../../Redux/actions/admin"
import { bindActionCreators } from "redux"
import { withCookies } from "react-cookie"
import adminChatApi from '../../../apis/admin/chat'
import { 
    ChakraProvider, 
    Box, 
    Textarea, 
    IconButton, 
    Text,
    Avatar, 
} from '@chakra-ui/react'
import { ArrowRightIcon } from '@chakra-ui/icons'

import { getAvatarColor } from '../../../Controllers/FunctionsController'
import NoSelectChatImg from '../../../assets/imgs/no_select_chat.svg'
import MessagesList from './MessagesList'

class InputChat extends React.Component {
    state = {
        text: ''
    }
    
    sendMessage = () => {
        this.props.onSendMessage(this.state.text)
        this.setState({text: ''})
    }

    render() {
        return <Box boxShadow='0 -2px 4px 0 rgb(0 0 0 / 4%)' display='flex' p='10px 15px'>
            <Textarea value={this.state.text} onChange={e => this.setState({text: e.target.value})} placeholder='Type a message...'></Textarea>
            <IconButton isDisabled={!/\S/.test(this.state.text)} onClick={this.sendMessage} colorScheme='red' borderRadius='50px' ml='10px' icon={<ArrowRightIcon />} size="md" />
        </Box>
    }
}

class App extends React.Component {
    componentDidMount() {
        window.addEventListener('mousemove', this.focusPage.bind(this))

        if(!!this.props.admin.chats.find(chat => chat._id === this.props.activeChatId) && !this.props.admin.chats.find(chat => chat._id === this.props.activeChatId).isGetted)
            adminChatApi.getChat({chatId: this.props.activeChatId}).then(response => {
                if(response.success) {
                    this.props.adminActions.getChat(this.props.activeChatId, response.messages)
                    
                    this.props.adminActions.readMessages(this.props.activeChatId)
                    adminChatApi.readMessages({chatId: this.props.activeChatId})
                }
            })
    }

    componentWillUnmount() {
        window.removeEventListener('mousemove', this.focusPage.bind(this));
    }

    focusPage() {
        if(!this.props.activeUserId && !!this.props.admin.chats.length && !!this.props.admin.chats.find(chat => chat._id === this.props.activeChatId).noRead) {
            this.props.adminActions.readMessages(this.props.activeChatId)
            adminChatApi.readMessages({chatId: this.props.activeChatId})
        }
    }

    sendMessage = (text) => {
        let data = {
            chatId: this.props.activeChatId,
            text
        }

        adminChatApi.sendMessage(data)
        
        this.props.adminActions.sendMessage(data.chatId, data.text)
    }

    render() {
        let chat = !!this.props.activeChatId ? this.props.admin.chats.find(chat => chat._id === this.props.activeChatId) : false

        return (<>
            <ChakraProvider resetCSS>
                {!this.props.activeChatId && <Box width='max-content' height='max-content' position='absolute' top='0' left='0' right='0' bottom='0' m='auto'>
                    <img src={NoSelectChatImg} width='250px' />
                    <Text
                        fontWeight="bold"
                        color="gray.500"
                        textAlign="center"
                        fontSize="2xl"
                        mt='10px'
                    >
                        Select chat
                    </Text>
                </Box>}

                {!!this.props.activeChatId && !!chat && <Box display='flex' flexDirection='column' height='100%'>
                    <Box boxShadow='0 2px 4px 0 rgb(0 0 0 / 4%)' height='80px' display='flex' alignItems='center'>
                        <Avatar m='0 15px' backgroundColor={getAvatarColor(chat.user.email || chat.user.uId)} size="md" />
                        <Text color="red.500" fontWeight="bold" fontSize="xl">
                            {chat.user.email || chat.user.uId}
                        </Text>
                    </Box>
                    <MessagesList activeChatId={this.props.activeChatId} messages={chat.messages} />
                    <InputChat onSendMessage={this.sendMessage} chatId={chat._id} />
                </Box>}
            </ChakraProvider>
        </>)
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        app: state.app,
        admin: state.admin,
        chats: state.chats,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        userActions: bindActionCreators(userActions, dispatch),
        appActions: bindActionCreators(appActions, dispatch),
        adminActions: bindActionCreators(adminActions, dispatch),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withCookies(withRouter(App)))
