import { 
    USER_LOGIN,
    USER_LOGOUT,
} from '../constants'
import SocketController from '../../Controllers/SocketController'

export const loginUser = (user, apiToken) => (dispatch) => {
    user.apiToken = apiToken
    SocketController.init(apiToken)
    
    dispatch({
        type: USER_LOGIN,
        payload: user
    })
}

export const logoutUser = () => (dispatch) => {
    dispatch({
        type: USER_LOGOUT
    })
}