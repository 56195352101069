import React from 'react'
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import * as userActions from "../../../Redux/actions/user"
import * as appActions from "../../../Redux/actions/app"
import * as adminActions from "../../../Redux/actions/admin"
import { bindActionCreators } from "redux"
import { withCookies } from "react-cookie"
import adminChatApi from '../../../apis/admin/chat'
import { 
    ChakraProvider, 
    Box, 
    Textarea, 
    IconButton, 
    Text,
    Avatar, 
} from '@chakra-ui/react'
import { ArrowRightIcon } from '@chakra-ui/icons'
import Message from './Message'

let scrollTop = 0

class App extends React.Component {
    constructor(props) {
        super(props)
        this.messagesBlock = React.createRef()  
    }

    state = {
        showMsgs: false
    }

    scrollToBottom = () => {
        if(this.messagesBlock) {
            this.messagesBlock.current.scrollTop = 100000
        }
    }

    componentDidMount() {
        if(!!this.messagesBlock.current) {
            setTimeout(this.scrollToBottom, 300)
        }

        this.setState({showMsgs: true})
    }

    componentDidUpdate(prevProps) {
        if(
            !!prevProps.messages.length && 
            (prevProps.messages.length < this.props.messages.length && 
            JSON.stringify(prevProps.messages[prevProps.messages.length-1]) === JSON.stringify(this.props.messages[this.props.messages.length-1]) &&
            ((this.messagesBlock.current.scrollHeight - this.messagesBlock.current.scrollTop) < this.messagesBlock.current.clientHeight+1000) ||
            prevProps.messages[0].user._id !== this.props.user._id ||
            this.props.activeChatId !== prevProps.activeChatId)
        ) {
            this.scrollToBottom()
        }

        // if(JSON.stringify(prevProps.messages[0]) !== JSON.stringify(this.props.messages[0])) {
        //     this.messagesBlock.scrollTop(this.messagesBlock.getScrollHeight() - scrollTop)
        // }
    }

    onScroll = () => {
        scrollTop = this.messagesBlock.current.scrollHeight - this.messagesBlock.current.scrollTop
    }

    render() {
        return (<>
            <ChakraProvider resetCSS>
                <div ref={this.messagesBlock} onScroll={this.onScroll} style={{height: '100%', overflow: 'auto',}}>
                    {this.state.showMsgs && this.props.messages.map(message => <Message message={message} key={message.createdAt} />)}
                </div>
            </ChakraProvider>
        </>)
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        app: state.app,
        admin: state.admin,
        chats: state.chats,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        userActions: bindActionCreators(userActions, dispatch),
        appActions: bindActionCreators(appActions, dispatch),
        adminActions: bindActionCreators(adminActions, dispatch),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withCookies(withRouter(App)))
