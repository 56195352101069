import { handleError, handleSuccess } from "."
import CookieController from "../Controllers/CookieController"

let apiUrl = `${process.env.REACT_APP_API_URL}/api/${process.env.REACT_APP_API_VERSION}/app/auth/`

const apis = {
    login: function(params = {}) {
        params.projectId = process.env.REACT_APP_PROJECT_ID
        params.uId = CookieController.get('uId')

        return fetch(`${apiUrl}login`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(params)
        })
        .then(handleSuccess)
        .catch(e => {
            console.error('auth api "login" error:', e)
            return handleError()
        })
    },
    register: function(params = {}) {
        params.projectId = process.env.REACT_APP_PROJECT_ID

        return fetch(`${apiUrl}register`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(params)
        })
        .then(handleSuccess)
        .catch(e => {
            console.error('auth api "register" error:', e)
            return handleError()
        })
    },
    remind: function(params = {}) {
        params.projectId = process.env.REACT_APP_PROJECT_ID

        return fetch(`${apiUrl}remind`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(params)
        })
        .then(handleSuccess)
        .catch(e => {
            console.error('auth api "remind" error:', e)
            return handleError()
        })
    },
}

export default apis