import { 
    ADMIN_ADD_MESSAGE,
    ADMIN_GET_CHATS,
    ADMIN_GET_CHAT,
    ADD_CHAT,
    ADMIN_READ,
    GET_USERS,
    ADD_USER,
    REMOVE_USER,
    ADMIN_GET_POLLS,
    ADMIN_ADD_POLL,
    ADMIN_REMOVE_POLL,
    ADMIN_ADD_ANSWER,
    SET_ACCESS,
    CHANGE_POLL_STATUS
} from '../constants'

const INITIAL_STATE = {
    chats: [],
    users: [],
    polls: [],
    access: false,
    isGettedChats: false,
    isGettedUsers: false,
    isGettedPolls: false,
    isGettedAccess: false
}

const user = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case ADMIN_GET_POLLS: {
            return { ...state, polls: action.payload, isGettedPolls: true }
        }
        case ADMIN_ADD_POLL: {
            return { ...state, polls: [ ...state.polls, {...action.payload, active: true} ] }
        }
        case ADMIN_REMOVE_POLL: {
            return { ...state, polls: [ ...state.polls.filter(poll => {        
                return poll.question != action.payload
            })] }
        }
        case CHANGE_POLL_STATUS: {
            return { ...state, polls: state.polls.map(poll => poll.question === action.payload.question ? {...poll, isEnabled: action.payload.isEnabled} : poll )}
        }
        case ADMIN_ADD_ANSWER: {
            return { ...state, polls: state.polls.map(poll => poll.question === action.payload.question ?
                {...poll, count: poll.count+1, options: action.payload.number !== 0 ? poll.options.map(option => option.number === action.payload.number ? {...option, count: option.count+1} : option) : poll.options } :
                poll
            ) }
        }
        case GET_USERS: {
            return { ...state, users: action.payload, isGettedUsers: true }
        }
        case ADD_USER: {
            return { ...state, users: [action.payload, ...state.users] }
        }
        case REMOVE_USER: {
            return { ...state, users: [ ...state.users.filter(user => {        
                return user.email != action.payload
            })] }
        }
        case ADMIN_GET_CHATS: {
            return { ...state, chats: action.payload, isGettedChats: true }
        }
        case ADMIN_GET_CHAT: {
            return { ...state, chats: state.chats.map(chat => chat._id === action.payload.chatId ?
                    {...chat, messages: action.payload.messages, isGetted: true} :
                    chat
                )
            }
        }
        case ADD_CHAT: {
            return { ...state, chats: [action.payload, ...state.chats] }
        }
        case ADMIN_READ: {
            return { ...state, chats: state.chats.map(chat => chat._id === action.payload ? {...chat, noRead: 0} : chat) }
        }
        case ADMIN_ADD_MESSAGE: {
            return { ...state, chats: state.chats.map(chat => chat._id === action.payload.chatId ? 
                    {...chat, messages: [ ...chat.messages, action.payload.message ], lastMessage: action.payload.message, noRead: action.payload.message.user._id === chat.user._id ? chat.noRead + 1 : chat.noRead} :
                    chat
                ).sort((a, b) => {
                    if(!(a.lastMessage && b.lastMessage))
                        return 0
    
                    a = new Date(a.lastMessage.createdAt)
                    b = new Date(b.lastMessage.createdAt)
                    
                    return a>b ? -1 : a<b ? 1 : 0
                })
            }
        }
        default:
            return state
    }
}

export default user